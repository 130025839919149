import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { Config } from '../../config/config';
import { LocalizeParser } from '@gilsdav/ngx-translate-router';
import { map, tap } from 'rxjs/operators';
import { makeStateKey } from '@angular/platform-browser';
class CustomTranslateLoader {
    constructor(http, transferState) {
        this.http = http;
        this.transferState = transferState;
    }
    getTranslation(lang) {
        const translationKey = makeStateKey(`translation_${lang}`);
        if (!this.transferState.hasKey(translationKey)) {
            return this.http.get(Config.ENDPOINTS.translations.language(lang))
                .pipe(map(res => res.data.translations), tap(data => this.transferState.set(translationKey, data)));
        }
        const language = this.transferState.get(translationKey, {});
        this.transferState.remove(translationKey);
        return of(language);
    }
}
class CustomRouterHttpLoader extends LocalizeParser {
    constructor(translate, location, settings, storeTranslateService, http, transferState) {
        super(translate, location, settings);
        this.storeTranslateService = storeTranslateService;
        this.http = http;
        this.transferState = transferState;
        this.localTranslateService = translate;
    }
    load(routes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const translationKey = makeStateKey(`translations`);
            return new Promise((resolve) => {
                if (this.transferState.hasKey(translationKey)) {
                    this.setData(this.transferState.get(translationKey, {}));
                    this.transferState.remove(translationKey);
                    this.init(routes).then(resolve);
                    return;
                }
                this.http.get(Config.ENDPOINTS.translations.languages())
                    // @ts-ignore
                    .subscribe(({ data }) => {
                    this.transferState.set(translationKey, data);
                    this.setData(data);
                    this.init(routes).then(resolve);
                });
            });
        });
    }
    setData(data) {
        this.locales = Object.keys(data.locales);
        this.storeTranslateService.setLanguages(data.locales);
        this.localTranslateService.addLangs(this.locales);
        this.prefix = data.prefix || '';
        this.escapePrefix = data.escapePrefix || '';
    }
}
export const CustomTranslateLoaderFactory = (http, transferState) => new CustomTranslateLoader(http, transferState);
export const LocalizeRouterHttpLoaderFactory = (translate, location, settings, storeTranslate, http, transferState) => new CustomRouterHttpLoader(translate, location, settings, storeTranslate, http, transferState);
