/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-control-validator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-control-validator.component";
var styles_FormControlValidatorComponent = [i0.styles];
var RenderType_FormControlValidatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormControlValidatorComponent, data: {} });
export { RenderType_FormControlValidatorComponent as RenderType_FormControlValidatorComponent };
function View_FormControlValidatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-danger font-weight-light form-error-elsa text-capitalize mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_FormControlValidatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-flex flex-column align-items-end"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormControlValidatorComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.elsaControl.getError(_co.ELSA_VALIDATION_FORMS_KEY); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FormControlValidatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormControlValidatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.elsaControl.hasError(_co.ELSA_VALIDATION_FORMS_KEY); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormControlValidatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-control-validator", [], null, null, null, View_FormControlValidatorComponent_0, RenderType_FormControlValidatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormControlValidatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormControlValidatorComponentNgFactory = i1.ɵccf("app-form-control-validator", i3.FormControlValidatorComponent, View_FormControlValidatorComponent_Host_0, { elsaControl: "elsaControl" }, {}, []);
export { FormControlValidatorComponentNgFactory as FormControlValidatorComponentNgFactory };
