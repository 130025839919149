import { Routes } from '@angular/router';
import { LocalizeRouterHttpLoaderFactory } from './helpers/custom-translate-loader';
import { SemiPath } from '../config/config';
const ɵ0 = () => import("./pages/root/root.module.ngfactory").then(r => r.RootModuleNgFactory), ɵ1 = () => import("./pages/products/products.module.ngfactory").then(r => r.ProductsModuleNgFactory), ɵ2 = () => import("./pages/about-us/about-us.module.ngfactory").then(r => r.AboutUsModuleNgFactory), ɵ3 = () => import("./pages/elsa/elsa.module.ngfactory").then(r => r.ElsaModuleNgFactory), ɵ4 = () => import("./pages/news/news.module.ngfactory").then(r => r.NewsModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
    {
        path: SemiPath.Products,
        loadChildren: ɵ1
    },
    {
        path: SemiPath.AboutUs,
        loadChildren: ɵ2
    },
    {
        path: SemiPath.Elsa,
        loadChildren: ɵ3
    },
    // {
    //   path: SemiPath.Contacts,
    //   loadChildren: () => import('./pages/contacts/contacts.module').then(r => r.ContactsModule)
    // },
    {
        path: SemiPath.News,
        loadChildren: ɵ4
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: `/${SemiPath.NotFound}`
    }
];
const ɵ5 = LocalizeRouterHttpLoaderFactory;
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
