import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[appEmptyTitle]'
})
export class EmptyTitleDirective {

  constructor(el: ElementRef, renderer: Renderer2) {
    const removeListener = renderer.listen(el.nativeElement, 'mouseover', () => {
      if (el.nativeElement.title) {
        el.nativeElement.title = '';
        removeListener();
      }
    });
  }
}
