import {Component, Inject, OnInit} from '@angular/core';
import {UtilsService} from '../../providers/services/utils.service';

@Component({
  selector: 'app-button-go-up',
  templateUrl: './button-go-up.component.html',
  styleUrls: ['./button-go-up.component.scss']
})
export class ButtonGoUpComponent implements OnInit {

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit() {
  }

  goUp() {
    if (this.utilsService.isPlatformBrowser) {
      window.scrollTo(0, 0);
    }
  }

}
