import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Cookies from 'js-cookie';
import { Config } from '../../../config/config';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { startWith, switchMap } from 'rxjs/operators';

/**
 * Api is a generic REST Api handler. Set your API url first.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private static API_KEYS = {
    lang: 'locale',
    bearer: 'Authorization',
    allowHeaders: 'Access-Control-Allow-Headers',
    contentType: 'Content-Type'
  };

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private routerTranslateService: LocalizeRouterService
  ) {
  }

  get(endpoint: string, params?: any, reqOpts?: any, locale?): Observable<any> {
    return this.http.get(endpoint, this.getHttpOptions(reqOpts, locale));
  }

  post(endpoint: string, body: any, reqOpts?: any, locale?): Observable<any> {
    return this.http.post(endpoint, body, this.getHttpOptions(reqOpts, locale));
  }

  postCRM(endpoint: string, body: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      }),
      observe: 'response',
      withCredentials: false
    };

    return this.http.post(endpoint, body);
  }

  put(endpoint: string, body: any, reqOpts?: any, locale?): Observable<any> {
    return this.http.put(endpoint, body, this.getHttpOptions(reqOpts, locale));
  }

  delete(endpoint: string, reqOpts?: any, locale?): Observable<any> {
    return this.http.delete(endpoint, this.getHttpOptions(reqOpts, locale));
  }

  translateApi(apis: (locale) => Observable<any>): Observable<any> {
    const locale = this.translateService.currentLang;
    return this.routerTranslateService.routerEvents.asObservable().pipe(startWith(locale), switchMap((l) => apis(l)));
  }

  getQueryParams(items: { [key: string]: string | string[] | number | number[] }): string {
    const result = Object.keys(items).map((key) => {
      let values;
      if (Array.isArray(items[key])) {
        // @ts-ignore
        values = items[key].join(',');
      } else {
        values = items[key];
      }
      return `${key}=${values}`;
    }).join('&');
    return `?${result}`;
  }

  getHttpOptions(reqOpts?: any, locale?): any {
    const x = {
      [ApiService.API_KEYS.contentType]: 'application/json;charset=utf-8',
      [ApiService.API_KEYS.allowHeaders]: '*'
    };
    if (Cookies.get(Config.COOKIE_KEYS.bearer)) {
      x[ApiService.API_KEYS.bearer] = Cookies.get(Config.COOKIE_KEYS.bearer);
    }
    if (!locale) {
      locale = this.translateService.currentLang;
    }
    if (locale) {
      x[ApiService.API_KEYS.lang] = locale;
    }

    if (reqOpts) {
      Object.keys(reqOpts).forEach(i => x[i] = reqOpts[i]);
    }
    const httpHeaders = new HttpHeaders(x);


    return {
      headers: httpHeaders,
      observe: 'response',
      withCredentials: false
    };
  }

}
