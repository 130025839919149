import {Pipe, PipeTransform} from '@angular/core';
import {UtilsService} from '../services/utils.service';
import {Paths} from '../../../config/config';

@Pipe({
  name: 'page'
})
export class PagePipe implements PipeTransform {

  constructor(private pluginUtils: UtilsService) {
  }

  transform(value: Paths, ...args): any | any[] {
    return this.pluginUtils.getLink(value, args);
  }

}
