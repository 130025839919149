import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TransferStateService {

  constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private  platformId, private translateService: TranslateService) {
  }

  public transferStateAlreadyHasTheKey(transferStateKey) {
    const key = makeStateKey(`${this.translateService.currentLang}-${transferStateKey}`);
    return isPlatformBrowser(this.platformId) && this.transferState.hasKey(key) ? key : false;
  }

  public getDataFromTransferStateAndRemoveIt(key) {
    const data = this.transferState.get(key, {});
    this.removeDataFromState(key);
    return data;
  }

  public saveTheDataInTheState(key, data: Observable<any>) {
    if (isPlatformServer(this.platformId)) {
      return data.pipe(tap(res => this.transferState.set(key, res)));
    }
    return data;
  }

  public runApi(keyString, api: Observable<any>) {
    const key = this.transferStateAlreadyHasTheKey(keyString);
    if (key) {
      return of(this.getDataFromTransferStateAndRemoveIt(key));
    }
    return this.saveTheDataInTheState(
      key,
      api
    );
  }

  public removeDataFromState(key) {
    this.transferState.remove(key);
  }
}
