import { Renderer2, RendererFactory2 } from '@angular/core';
import { Config } from '../../../config/config';
import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@gilsdav/ngx-translate-router";
export class UtilsService {
    constructor(localizeRouterTranslateService, platformId, rendererFactory) {
        this.localizeRouterTranslateService = localizeRouterTranslateService;
        this.platformId = platformId;
        this.renderer2 = rendererFactory.createRenderer(null, null);
    }
    get isPlatformBrowser() {
        return isPlatformBrowser(this.platformId);
    }
    getLink(namePage, ...values) {
        return Config.PATHS_VALUE[namePage](values && values.length ? values[0] : void 0).join('/');
    }
    openLink(link) {
        const a = this.renderer2.createElement('a');
        this.renderer2.setAttribute(a, 'target', '_blank');
        this.renderer2.setAttribute(a, 'href', link);
        a.click();
    }
}
UtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UtilsService_Factory() { return new UtilsService(i0.ɵɵinject(i1.LocalizeRouterService), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i0.RendererFactory2)); }, token: UtilsService, providedIn: "root" });
