import { HttpHeaders } from '@angular/common/http';
import * as Cookies from 'js-cookie';
import { Config } from '../../../config/config';
import { startWith, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@gilsdav/ngx-translate-router";
/**
 * Api is a generic REST Api handler. Set your API url first.
 */
export class ApiService {
    constructor(http, translateService, routerTranslateService) {
        this.http = http;
        this.translateService = translateService;
        this.routerTranslateService = routerTranslateService;
    }
    get(endpoint, params, reqOpts, locale) {
        return this.http.get(endpoint, this.getHttpOptions(reqOpts, locale));
    }
    post(endpoint, body, reqOpts, locale) {
        return this.http.post(endpoint, body, this.getHttpOptions(reqOpts, locale));
    }
    postCRM(endpoint, body) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            }),
            observe: 'response',
            withCredentials: false
        };
        return this.http.post(endpoint, body);
    }
    put(endpoint, body, reqOpts, locale) {
        return this.http.put(endpoint, body, this.getHttpOptions(reqOpts, locale));
    }
    delete(endpoint, reqOpts, locale) {
        return this.http.delete(endpoint, this.getHttpOptions(reqOpts, locale));
    }
    translateApi(apis) {
        const locale = this.translateService.currentLang;
        return this.routerTranslateService.routerEvents.asObservable().pipe(startWith(locale), switchMap((l) => apis(l)));
    }
    getQueryParams(items) {
        const result = Object.keys(items).map((key) => {
            let values;
            if (Array.isArray(items[key])) {
                // @ts-ignore
                values = items[key].join(',');
            }
            else {
                values = items[key];
            }
            return `${key}=${values}`;
        }).join('&');
        return `?${result}`;
    }
    getHttpOptions(reqOpts, locale) {
        const x = {
            [ApiService.API_KEYS.contentType]: 'application/json;charset=utf-8',
            [ApiService.API_KEYS.allowHeaders]: '*'
        };
        if (Cookies.get(Config.COOKIE_KEYS.bearer)) {
            x[ApiService.API_KEYS.bearer] = Cookies.get(Config.COOKIE_KEYS.bearer);
        }
        if (!locale) {
            locale = this.translateService.currentLang;
        }
        if (locale) {
            x[ApiService.API_KEYS.lang] = locale;
        }
        if (reqOpts) {
            Object.keys(reqOpts).forEach(i => x[i] = reqOpts[i]);
        }
        const httpHeaders = new HttpHeaders(x);
        return {
            headers: httpHeaders,
            observe: 'response',
            withCredentials: false
        };
    }
}
ApiService.API_KEYS = {
    lang: 'locale',
    bearer: 'Authorization',
    allowHeaders: 'Access-Control-Allow-Headers',
    contentType: 'Content-Type'
};
ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.LocalizeRouterService)); }, token: ApiService, providedIn: "root" });
