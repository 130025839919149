<div class="d-flex flex-column w-100 position-relative" #top>
  <picture>
    <img [src]="news.img" class="w-100 object-fit-cover news-img" [alt]="news.title">
  </picture>
  <div class="container">
    <div class="row">
      <div class="col-60 pt-2 px-4">
        <span class="news-date">{{news.date | myDate: 'L'}}</span>
        <h2 class="elsa-title-font news-title">{{news.title}}</h2>
        <div class="w-100 news-description" [innerHTML]="news.description | safeHtml"></div>
        <div class="d-flex justify-content-center w-100">
          <button class="btn btn-danger btn-close-modal btn-sm text-uppercase" (click)="modalRef.close()">{{'label_close' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
  <a title="Elsa" class="btn-close-modal-top" (click)="modalRef.close()" appEmptyTitle><i class="fas fa-times"></i></a>
</div>
