import { makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@ngx-translate/core";
export class TransferStateService {
    constructor(transferState, platformId, translateService) {
        this.transferState = transferState;
        this.platformId = platformId;
        this.translateService = translateService;
    }
    transferStateAlreadyHasTheKey(transferStateKey) {
        const key = makeStateKey(`${this.translateService.currentLang}-${transferStateKey}`);
        return isPlatformBrowser(this.platformId) && this.transferState.hasKey(key) ? key : false;
    }
    getDataFromTransferStateAndRemoveIt(key) {
        const data = this.transferState.get(key, {});
        this.removeDataFromState(key);
        return data;
    }
    saveTheDataInTheState(key, data) {
        if (isPlatformServer(this.platformId)) {
            return data.pipe(tap(res => this.transferState.set(key, res)));
        }
        return data;
    }
    runApi(keyString, api) {
        const key = this.transferStateAlreadyHasTheKey(keyString);
        if (key) {
            return of(this.getDataFromTransferStateAndRemoveIt(key));
        }
        return this.saveTheDataInTheState(key, api);
    }
    removeDataFromState(key) {
        this.transferState.remove(key);
    }
}
TransferStateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TransferStateService_Factory() { return new TransferStateService(i0.ɵɵinject(i1.TransferState), i0.ɵɵinject(i0.PLATFORM_ID), i0.ɵɵinject(i2.TranslateService)); }, token: TransferStateService, providedIn: "root" });
