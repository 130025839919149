import { Observable } from 'rxjs';
import { Config } from '../../../config/config';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./transfer-state.service";
export class CatalogService {
    constructor(apiService, transferState) {
        this.apiService = apiService;
        this.transferState = transferState;
    }
    getProductInEvidence() {
        return this.transferState.runApi('catalog-product-in-evidence', this.apiService.translateApi((locale) => this.apiService.get(Config.ENDPOINTS.catalog.productInEvidence(), undefined, undefined, locale)));
    }
    getApplications() {
        return this.transferState.runApi('catalog-applications', this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.applications(), undefined, undefined, locale)));
    }
    getSeries() {
        return this.transferState.runApi('catalog-series', this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.series(), undefined, undefined, locale)));
    }
    getSerieInEvidence() {
        return this.transferState.runApi('catalog-serie-in-evidence', this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.serieInEvidence(), undefined, undefined, locale)));
    }
    getProducts() {
        return this.transferState.runApi('catalog-products', this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.allProducts(), undefined, undefined, locale)));
    }
    getProduct(slug) {
        return this.transferState.runApi(`catalog-product-${slug}`, this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.product(slug), undefined, undefined, locale)));
    }
    getAccessoriesCategories(machine = '') {
        return this.transferState.runApi(`catalog-accessories-categories-${machine}`, this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.accessoriesCategories(machine), undefined, undefined, locale)));
    }
    getKitseries() {
        return this.transferState.runApi('catalog-kitseries', this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.kitseries(), undefined, undefined, locale)));
    }
    getKitserie(slug) {
        return this.transferState.runApi(`catalog-kitserie-${slug}`, this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.kitserie(slug), undefined, undefined, locale)));
    }
    getProductsWithLocale(locale) {
        return this.transferState.runApi('catalog-products', this.apiService.get(Config.ENDPOINTS.catalog.allProducts(), undefined, undefined, locale));
    }
    getKitseriesWithLocale(locale) {
        return this.transferState.runApi('catalog-kitseries', this.apiService.get(Config.ENDPOINTS.catalog.kitseries(), undefined, undefined, locale));
    }
    getProductInEvidenceWithLocale(locale) {
        return this.transferState.runApi('catalog-product-in-evidence', this.apiService.get(Config.ENDPOINTS.catalog.productInEvidence(), undefined, undefined, locale));
    }
    compareProducts(firstProduct, secondaryProduct) {
        return this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.compareProducts(firstProduct, secondaryProduct), undefined, undefined, locale));
    }
}
CatalogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogService_Factory() { return new CatalogService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.TransferStateService)); }, token: CatalogService, providedIn: "root" });
