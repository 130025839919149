import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { Forms } from './form.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(private utilsService: UtilsService) {
  }

  public pushEventToDataLayer(event: any) {
    if (this.utilsService.isPlatformBrowser) {
      const dataLayer = window.dataLayer;
      dataLayer.push(event);
    }
  }

  public formSubmission(type: Forms) {
    this.pushEventToDataLayer({
      event: 'formSubmission',
      formType: type
    });
  }
}
