import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmptyTitleDirective} from './empty-title.directive';
import { AnimatedDigitDirective } from './animated-digit.directive';


@NgModule({
  declarations: [EmptyTitleDirective, AnimatedDigitDirective],
  imports: [
    CommonModule
  ],
  exports: [EmptyTitleDirective, AnimatedDigitDirective]
})
export class DirectivesModule {
}
