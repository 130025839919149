/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./social.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../providers/directives/empty-title.directive";
import * as i3 from "@angular/common";
import * as i4 from "./social.component";
var styles_SocialComponent = [i0.styles];
var RenderType_SocialComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SocialComponent, data: {} });
export { RenderType_SocialComponent as RenderType_SocialComponent };
function View_SocialComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "border-left border-white social-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["appEmptyTitle", ""], ["class", "text-white"], ["target", "_blank"]], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.EmptyTitleDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["height", "15"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.url; var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.img_white.path; var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.title, ""); _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_SocialComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "d-none d-lg-flex flex-row align-items-center h-100"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SocialComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.socials; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SocialComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-social", [], null, null, null, View_SocialComponent_0, RenderType_SocialComponent)), i1.ɵdid(1, 114688, null, 0, i4.SocialComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SocialComponentNgFactory = i1.ɵccf("app-social", i4.SocialComponent, View_SocialComponent_Host_0, { socials: "socials" }, {}, []);
export { SocialComponentNgFactory as SocialComponentNgFactory };
