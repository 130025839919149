/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./news.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../providers/pipes/my-date.pipe";
import * as i3 from "../../../providers/services/utils.service";
import * as i4 from "../../../providers/pipes/safe-html.pipe";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../providers/directives/empty-title.directive";
import * as i8 from "./news.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "@angular/common";
var styles_NewsComponent = [i0.styles];
var RenderType_NewsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NewsComponent, data: {} });
export { RenderType_NewsComponent as RenderType_NewsComponent };
export function View_NewsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.MyDatePipe, [i3.UtilsService]), i1.ɵpid(0, i4.SafeHtmlPipe, [i5.DomSanitizer]), (_l()(), i1.ɵeld(2, 0, [["top", 1]], null, 19, "div", [["class", "d-flex flex-column w-100 position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "picture", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "w-100 object-fit-cover news-img"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "col-60 pt-2 px-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "news-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h2", [["class", "elsa-title-font news-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "w-100 news-description"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(14, 1), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "d-flex justify-content-center w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-danger btn-close-modal btn-sm text-uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["appEmptyTitle", ""], ["class", "btn-close-modal-top"], ["title", "Elsa"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.modalRef.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, null, 0, i7.EmptyTitleDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.news.img; var currVal_1 = _co.news.title; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.news.date, "L")); _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.news.title; _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 1), _co.news.description)); _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("label_close")); _ck(_v, 17, 0, currVal_5); }); }
export function View_NewsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-news", [], null, null, null, View_NewsComponent_0, RenderType_NewsComponent)), i1.ɵdid(1, 4243456, null, 0, i8.NewsComponent, [i9.NgbActiveModal, i10.DOCUMENT, i3.UtilsService, i1.NgZone], null, null)], null, null); }
var NewsComponentNgFactory = i1.ɵccf("app-news", i8.NewsComponent, View_NewsComponent_Host_0, {}, {}, []);
export { NewsComponentNgFactory as NewsComponentNgFactory };
