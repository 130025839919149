import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbService} from '../../providers/services/breadcrumb.service';
import {PathNames, Paths} from '../../../config/config';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public readonly Paths = Paths;
  public readonly PathNames = PathNames;
  @ViewChild('backgroundRef', {static: true}) private backgroundRef: ElementRef;

  constructor(public breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event'])
  public onScroll(event) {
    if (event.target.documentElement.scrollTop) {
      this.backgroundRef.nativeElement.classList.add('breadcrumb-gray');
    } else {
      this.backgroundRef.nativeElement.classList.remove('breadcrumb-gray');
    }
  }

}
