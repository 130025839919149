<div class="container mb-5">
  <div class="row">
    <div class="col-60">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <a title="Elsa" appEmptyTitle (click)="modalActive.close()" class="my-3">
          <img src="assets/icons/icon_close_black.svg" width="60" alt="Elsa srl">
        </a>
      </div>
    </div>
    <div class="col-60">
      <div class="d-flex flex-column align-items-center w-100">
        <span class="comparison-title elsa-title-font ">{{staticData?.title}}</span>
        <span class="comparison-subtitle elsa-title-font">{{staticData?.subtitle}}</span>
      </div>
    </div>
  </div>
  <form [formGroup]="formGroup" class="row">
    <div class="col-30 col-md-26">
      <select formControlName="left" class="form-control">
        <option [value]="''">{{'label_select_machine'|translate|uppercase}}</option>
        <option *ngFor="let product of products" [value]="product.name_slug">{{product.name}}</option>
      </select>
    </div>
    <div class="d-none d-md-flex justify-content-center col-8 versus align-items-center">
      {{'label_versus'|translate}}
    </div>
    <div class="col-30 col-md-26">
      <select formControlName="right" class="form-control">
        <option [value]="''" class="text-uppercase">{{'label_select_machine'|translate|uppercase}}</option>
        <option *ngFor="let product of products" [value]="product.name_slug">{{product.name}}</option>
      </select>
    </div>
  </form>
  <div class="row" *ngIf="productsApi">
    <div class="col-30 col-md-26 d-flex justify-content-center">
      <img class="img-product" [src]="productsApi[formGroup.get('left').value].image_header.path">
    </div>
    <div class="col-30 offset-md-8 col-md-26 d-flex justify-content-center">
      <img class="img-product" [src]="productsApi[formGroup.get('right').value].image_header.path">

    </div>
  </div>
  <div class="row" *ngFor="let item of comparison">
    <div class="col-60">
      <h5 class="text-center elsa-title-font text-uppercase category-title">
        {{item.title}}
      </h5>
      <div class="row">
        <div class="col-30 col-md-26 d-flex justify-content-center">
          <p class="value">{{item[formGroup.get('left').value]}}</p>
        </div>
        <div class="col-30  offset-md-8 col-md-26 d-flex justify-content-center">
          <p class="value">{{item[formGroup.get('right').value]}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4" *ngIf="productsApi">
    <div class=" col-30 col-md-26 d-flex justify-content-center">
      <a (click)="modalActive.close()" [routerLink]="Paths.Product | page:[productsApi[formGroup.get('left').value].name_slug] | localize" class="btn btn-elsa btn-gray text-uppercase" [title]="productsApi[formGroup.get('left').value].name" appEmptyTitle>{{'label_details'| translate}}</a>
    </div>
    <div class=" col-30  offset-md-8 col-md-26 d-flex justify-content-center">
      <a (click)="modalActive.close()" [routerLink]="Paths.Product | page:[productsApi[formGroup.get('right').value].name_slug] | localize" class="btn btn-elsa btn-gray text-uppercase" [title]="productsApi[formGroup.get('right').value].name" appEmptyTitle>{{'label_details'| translate}}</a>
    </div>
  </div>
</div>
