import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {filter, switchMap, tap} from 'rxjs/operators';
import {CatalogService} from '../../../providers/services/catalog.service';
import {Paths} from '../../../../config/config';

@Component({
  selector: 'app-comparison',
  templateUrl: './comparison.component.html',
  styleUrls: ['./comparison.component.scss']
})
export class ComparisonComponent implements OnInit, OnDestroy {

  public formGroup: FormGroup;
  public products;
  public productsApi;
  public comparison;
  public staticData;

  public readonly Paths = Paths;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public modalActive: NgbActiveModal, private fb: FormBuilder, private catalogService: CatalogService
  ) {
    this.formGroup = this.fb.group({left: '', right: ''});
    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(
        tap(() => this.productsApi = undefined),
        filter(({left, right}) => left && right),
        switchMap(({left, right}) => this.catalogService.compareProducts(left, right))
      ).subscribe(({body: {data}}) => {
        this.productsApi = data.products;
        this.comparison = data.comparison;
      }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
