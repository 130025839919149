import {AfterContentInit, AfterViewInit, Component, Inject, NgZone, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DOCUMENT} from '@angular/common';
import {UtilsService} from '../../../providers/services/utils.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements AfterViewInit {

  public news;

  constructor(public modalRef: NgbActiveModal, @Inject(DOCUMENT) private document, private projectService: UtilsService, private zone: NgZone) {
  }

  ngAfterViewInit() {
    if (this.projectService.isPlatformBrowser) {
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          const window = this.document.getElementsByClassName('news-window') as HTMLCollection;
          if (window.length && window.item(0).children.length && window.item(0).children[0].children.length) {
            window.item(0).children[0].children[0].scrollTo({top: 0});
          }
        }, 50);
      });
    }
  }


}
