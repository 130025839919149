import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {Config, Paths, StaticPage} from '../../../config/config';
import {TransferStateService} from './transfer-state.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private apiService: ApiService, private transferState: TransferStateService) {
  }

  public getSeoPage(slug: Paths): Observable<any> {
    return this.transferState.runApi(
      `pages-page-${slug}-seo`,
      this.apiService.translateApi(lang => this.apiService.get(Config.ENDPOINTS.pages.seo(slug), undefined, undefined, lang))
    );
  }

  public getPage(slug: StaticPage): Observable<any> {
    return this.transferState.runApi(
      `pages-page-${slug}`,
      this.apiService.translateApi(lang => this.apiService.get(Config.ENDPOINTS.pages.page(slug), undefined, undefined, lang))
    );
  }

  public getHistories(): Observable<any> {
    return this.transferState.runApi(
      'pages-histories',
      this.apiService.translateApi(lang => this.apiService.get(Config.ENDPOINTS.pages.histories(), undefined, undefined, lang))
    );
  }

  public getApplicationsWithLocale(locale): Observable<any> {
    return this.transferState.runApi(
      'pages-applications',
      this.apiService.get(Config.ENDPOINTS.pages.applications(), undefined, undefined, locale)
    );
  }

  public getHistoriesWithLocale(locale): Observable<any> {
    return this.transferState.runApi(
      'pages-histories',
      this.apiService.get(Config.ENDPOINTS.pages.histories(), undefined, undefined, locale)
    );
  }

  public getPageWithLocale(slug: StaticPage, locale): Observable<any> {
    return this.transferState.runApi(
      `pages-page-${slug}`,
      this.apiService.get(Config.ENDPOINTS.pages.page(slug), undefined, undefined, locale)
    );
  }
}
