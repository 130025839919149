import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SectionTitle1Component} from './section-title1/section-title1.component';
import {SectionTitle2Component} from './section-title2/section-title2.component';
import {BannerComponent} from './banner/banner.component';
import {SectionTitle3Component} from './section-title3/section-title3.component';
import {Banner2Component} from './banner2/banner2.component';
import {FormControlValidatorComponent} from './form-control-validator/form-control-validator.component';
import {VideoImageGalleryComponent} from './video-image-gallery/video-image-gallery.component';
import {NgxGalleryModule} from "ngx-image-video-gallery";
import { ScriptLdJsonComponent } from './script-ld-json/script-ld-json.component';
import { BannerNoImageComponent } from './banner-no-image/banner-no-image.component';
import { ShareComponent } from './share/share.component';
import {TranslateModule} from '@ngx-translate/core';
import { SectionTitle25Component } from './section-title25/section-title25.component';
import {PipesModule} from '../providers/pipes/pipes.module';


@NgModule({
  declarations: [SectionTitle1Component, SectionTitle2Component, BannerComponent, SectionTitle3Component, Banner2Component, FormControlValidatorComponent, VideoImageGalleryComponent, ScriptLdJsonComponent, BannerNoImageComponent, ShareComponent, SectionTitle25Component],
    imports: [
        CommonModule,
        NgxGalleryModule,
        TranslateModule,
        PipesModule
    ],
  exports: [SectionTitle1Component, SectionTitle2Component, BannerComponent, Banner2Component, SectionTitle3Component, FormControlValidatorComponent, VideoImageGalleryComponent, ScriptLdJsonComponent, BannerNoImageComponent, ShareComponent, SectionTitle25Component]
})
export class WidgetsModule {
}
