import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QuotationComponent} from './quotation/quotation.component';
import {NewsComponent} from './news/news.component';
import {PipesModule} from '../../providers/pipes/pipes.module';
import {DirectivesModule} from '../../providers/directives/directives.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WidgetsModule} from '../../widgets/widgets.module';
import {TranslateModule} from '@ngx-translate/core';
import {ComparisonComponent} from './comparison/comparison.component';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [QuotationComponent, NewsComponent, ComparisonComponent],
  imports: [
    CommonModule,
    PipesModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetsModule,
    TranslateModule,
    LocalizeRouterModule,
    RouterModule
  ],
  exports: [],
  entryComponents: [QuotationComponent, NewsComponent, ComparisonComponent]
})
export class ModalsModule {
}
