import { Component, NgZone, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Config, PathNames, Paths } from '../../../config/config';
import { StoreTranslateService } from '../../providers/services/store-translate.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { map, startWith, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ContactsService } from '../../providers/services/contacts.service';
import { Observable, Subscription } from 'rxjs';
import { NgbModal, NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { QuotationComponent } from '../../components/modals/quotation/quotation.component';
import { CatalogService } from '../../providers/services/catalog.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbPopoverConfig]
})
export class NavbarComponent implements OnInit, OnDestroy {

  @ViewChildren(NgbPopover) private popovers: QueryList<NgbPopover>;

  public readonly getFlagIcon = Config.getFlagIcon;
  public readonly Paths = Paths;
  public readonly PathNames = PathNames;
  public languages;
  public langObservable: Observable<any>;
  public langObservableLabel;
  public contacts;
  public products;
  public kits;
  private readonly subscriptions: Subscription = new Subscription();
  private mouseOnAboutUsLabel;
  private mouseOnAboutUs;
  private mouseOnProductLabel;
  private mouseOnProducts;
  private mouseOnContactsLabel;
  private mouseOnContacts;

  constructor(
    public translateService: LocalizeRouterService,
    private storeService: StoreTranslateService,
    private ngxTranslateService: TranslateService,
    private contactService: ContactsService,
    private modalService: NgbModal,
    config: NgbPopoverConfig,
    private ngZone: NgZone,
    private catalogService: CatalogService
  ) {
    config.placement = 'bottom-left';
    this.languages = this.storeService.getLanguages();
    this.langObservable = this.translateService.routerEvents.asObservable().pipe(startWith(this.ngxTranslateService.currentLang));
    this.langObservableLabel = this.langObservable.pipe(
      switchMap(res => this.storeService.getLanguages().pipe(map(r => Object.values(r).length ? r[res] : '')))
    );
    this.subscriptions.add(this.contactService.get()
      .pipe(map(res => res.body.data))
      .subscribe((contacts) => this.contacts = contacts));
    this.subscriptions.add(
      this.catalogService.getProducts().subscribe(({ body }) => {
        this.products = body.data.products;
      })
    );
    this.subscriptions.add(
      this.catalogService.getKitseries().pipe(map(({ body: { data: { kitseries } } }) => kitseries)).subscribe(res => this.kits = res)
    );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openQuotation() {
    this.modalService.open(QuotationComponent, {
      backdropClass: 'modals-backdrop-custom',
      windowClass: 'quotation-window'
    });
  }

  onMouseOverOnAboutUsLabel() {
    this.mouseOnAboutUsLabel = true;
    this.checkAboutUsPopover();
  }

  onMouseLeaveFromAboutUsLabel() {
    this.mouseOnAboutUsLabel = false;
    this.checkAboutUsPopover();
  }

  onMouseOverOnAboutUs() {
    this.mouseOnAboutUs = true;
    this.checkAboutUsPopover();
  }

  onMouseLeaveFromAboutUs() {
    this.mouseOnAboutUs = false;
    this.checkAboutUsPopover();
  }

  onMouseOverOnProductLabel() {
    this.mouseOnProductLabel = true;
    this.checkProductsPopover();
  }

  onMouseLeaveFromProductLabel() {
    this.mouseOnProductLabel = false;
    this.checkProductsPopover();
  }

  onMouseOverOnProducts() {
    this.mouseOnProducts = true;
    this.checkProductsPopover();
  }

  onMouseLeaveFromProducts() {
    this.mouseOnProducts = false;
    this.checkProductsPopover();
  }

  onMouseOverOnContactsLabel() {
    this.mouseOnContactsLabel = true;
    this.checkContactsPopover();
  }

  onMouseLeaveFromContactsLabel() {
    this.mouseOnContactsLabel = false;
    this.checkContactsPopover();
  }

  onMouseOverOnContacts() {
    this.mouseOnContacts = true;
    this.checkContactsPopover();
  }

  onMouseLeaveFromContacts() {
    this.mouseOnContacts = false;
    this.checkContactsPopover();
  }

  private checkAboutUsPopover() {
    this.handlePopover('aboutUsPopover', this.mouseOnAboutUsLabel, this.mouseOnAboutUs);
  }

  private checkProductsPopover() {
    this.handlePopover('productsPopover', this.mouseOnProductLabel, this.mouseOnProducts);
  }

  private checkContactsPopover() {
    this.handlePopover('contactsPopover', this.mouseOnContactsLabel, this.mouseOnContacts);
  }

  private handlePopover(ngbPopoverId: string, mouseOnLabel: boolean, mouseOnPopover: boolean) {
    const ngbPopover = this.getPopover(ngbPopoverId);
    if (!ngbPopover) {return; }

    if (mouseOnLabel || mouseOnPopover) {
      this.ngZone.runOutsideAngular(() =>
        setTimeout(() =>
            this.ngZone.run(() => {
              if (mouseOnLabel || mouseOnPopover) {
                ngbPopover.open();
              }
            }),
          300));
    } else {
      this.ngZone.runOutsideAngular(() =>
        setTimeout(() =>
            this.ngZone.run(() => {
              if (!mouseOnLabel && !mouseOnPopover) {
                ngbPopover.close();
              }
            }),
          500));
    }
  }

  private getPopover(popoverClass: string) {
    return this.popovers.find(popover => popover.popoverClass === popoverClass);
  }
}
