import { of } from 'rxjs';
import { Config } from '../../../config/config';
import { catchError, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
export class FilesService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    getEthic() {
        return this.getFile(Config.STATIC_FILES.ethic);
    }
    getOrganizationAct() {
        return this.getFile(Config.STATIC_FILES.organizationAct);
    }
    getOrganizationModel() {
        return this.getFile(Config.STATIC_FILES.organizationModel);
    }
    getLocalizedCatalog(locale) {
        return this.getLocalizedFile(Config.STATIC_FILES.catalog, locale);
    }
    getLocalizedCaseStudy(locale) {
        return this.getLocalizedFile(Config.STATIC_FILES.caseStudy, locale);
    }
    getFile(slug) {
        return this.apiService.get(Config.ENDPOINTS.files.file(slug)).pipe(map(({ body }) => body.path ? body.path : null), catchError(() => of(null)));
    }
    getLocalizedFile(slug, locale) {
        const localizedSlug = locale ? slug + '-' + locale : slug;
        return this.getFile(localizedSlug);
    }
}
FilesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FilesService_Factory() { return new FilesService(i0.ɵɵinject(i1.ApiService)); }, token: FilesService, providedIn: "root" });
