import { CustomTranslateLoaderFactory } from './helpers/custom-translate-loader';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
const DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    preloadImages: false,
    speed: 800,
    autoplay: false,
    loop: true,
    mousewheel: true,
    navigation: false,
    pagination: {
        type: 'bullets',
        el: '.swiper-pagination',
        clickable: true
    }
};
const ɵ0 = CustomTranslateLoaderFactory, ɵ1 = DEFAULT_SWIPER_CONFIG;
export class AppModule {
}
export { ɵ0, ɵ1 };
