<div class="position-fixed w-100" style="z-index: 10000; top: 0" id="navbar">
  <div class="container-fluid">

    <app-header-link-in-evidence></app-header-link-in-evidence>

    <!-- Header first row with green background -->
    <div class="row">
      <div class="col-60 bg-success">
        <div class="d-flex text-white flex-row justify-content-center w-100 ">
          <div class="w-100 container-fluid-960">
            <div class="row">
              <div class="col-60">
                <div class="d-flex flex-row justify-content-between w-100 align-items-center navbar-height-1">
                  <a [routerLink]="Paths.Homepage | page | localize" title="Homepage | Elsa Srl" class="d-lg-none">
                    <img
                      style="filter: brightness(0) invert(1);"
                      src="https://media.elsasrl.com/storage/elsa-platform/media/be-xtra/logo-elsa-site.png"
                      alt="Homepage | Elsa Srl" width="120">
                  </a>
                  <span class="d-none d-lg-block">{{'navbar_title' | translate}}</span>
                  <div class="d-flex flex-row justify-content-end align-items-center h-100">
                    <div class="h-100" ngbDropdown display="static">
                      <a ngbDropdownToggle class="d-flex flex-row align-items-center h-100 px-sm-2 px-lg-4"
                         title="Elsa Srl" appEmptyTitle>
                        <img alt="Elsa Srl" height="24" [src]="getFlagIcon(langObservable | async)"
                             class="mr-1 mr-md-0">
                        <div class="d-none d-md-inline-block mx-2">
                          {{langObservableLabel | async}}
                        </div>
                        <img alt="Elsa Srl" src="assets/icons/icon_down_arrow_small.svg" class="custom-angle d-none"
                             height="7">
                        <img alt="Elsa Srl" src="assets/icons/icon_down_arrow_small.svg" class="custom-angle-borded"
                             height="7">
                      </a>
                      <div ngbDropdownMenu>
                        <ng-container *ngFor="let lang of languages | async | keyvalue">
                          <a ngbDropdownItem *ngIf="lang.key != (langObservable | async)" title="Elsa Srl" appEmptyTitle
                             (click)="translateService.changeLanguage(lang.key)">
                            <img [alt]="lang.value" height="20" [src]="getFlagIcon(lang.key)" class="mr-2">
                            <span>{{lang.value}}</span>
                          </a>
                        </ng-container>
                      </div>
                    </div>
                    <div class="d-inline d-lg-none h-100 search-spacer">
                      <app-search class="h-100"></app-search>
                    </div>
                    <app-menu class="h-100" style="width: 25px" [kits]="kits"
                              [products]="products"></app-menu>
                    <app-social class="h-100" [socials]="contacts?.socials"></app-social>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Header second row with white background -->
    <div class="row">
      <div class="col-60 bg-white">
        <div class="d-flex flex-row justify-content-center w-100">
          <div class="w-100 container-fluid-960">
            <div class="row">
              <div class="col-35 col-md-45 col-lg-60 spacer-md-5">
                <div class="d-flex flex-row w-100 justify-content-between navbar-height-2 px-2">
                  <div class="d-none d-lg-flex flex-row align-items-center">
                    <a [routerLink]="Paths.Homepage | page | localize" title="Homepage | Elsa Srl" appEmptyTitle>
                      <img alt="Homepage | Elsa Srl"
                           src="https://media.elsasrl.com/storage/elsa-platform/media/be-xtra/logo-elsa-site.png"
                           height="65">
                    </a>
                  </div>

                  <div class="d-flex flex-row align-items-center">
                    <a [title]="'label_phone' | translate" appEmptyTitle [href]="'tel:'+contacts?.default.phone.number">
                      <img src="assets/icons/icon_phone.svg" height="25" alt="Elsa Phone">
                    </a>
                    <div class="ml-2 d-none d-md-flex flex-column justify-content-center">
                      <span class="font-weight-bold nav2-item text-success">
                        <a target="_blank" title="Elsa srl" appEmptyTitle
                           [href]="'tel:'+contacts?.default.phone.number">{{contacts?.default.phone.number}}</a></span>
                      <span class="font-weight-regular nav2-item-label">{{'label_phone' | translate}}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center">
                    <a [href]="'mailto:'+contacts?.default.email.email" [title]="'label_email' | translate"
                       appEmptyTitle>
                      <img src="assets/icons/icon_paper_plane.svg" height="25" alt="Elsa E-mail">
                    </a>
                    <div class="ml-2 d-none d-md-flex flex-column justify-content-center">
                      <span class="font-weight-bold nav2-item text-success">
                        <a target="_blank" title="Elsa srl" appEmptyTitle
                           [href]="'mailto:'+contacts?.default.email.email">{{contacts?.default.email.email}}</a></span>
                      <span class="font-weight-regular nav2-item-label">{{'label_email' | translate}}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-row align-items-center">
                    <a target="_blank" [title]="'label_place' | translate" appEmptyTitle
                       [href]="contacts?.default.position.maps">
                      <img src="assets/icons/icon_place.svg" height="25" alt="Elsa Place">
                    </a>
                    <div class="ml-2 d-none d-md-flex flex-column justify-content-center">
                      <span class="font-weight-bold nav2-item text-success">
                        <a target="_blank" title="Elsa srl" appEmptyTitle
                           [href]="contacts?.default.position.maps">{{contacts?.default.position.address_line_1}}</a></span>
                      <span class="font-weight-regular nav2-item-label">{{'label_place' | translate}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-25 col-md-15 bg-danger d-lg-none">
                <div class="d-flex justify-content-center align-items-center py-3 navbar-height-2">
                  <a class="nav-button-quotation elsa-title-font elsa-transition text-center"
                     [title]="'label_quotation' | translate" appEmptyTitle
                     (click)="openQuotation()">{{'label_quotation' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Header third row with navigation menu -->
    <div class="row">
      <div class="col-60 bg-transparent">
        <div class="d-flex flex-row justify-content-center w-100">
          <div class="w-100 container-fluid-960">
            <div class="d-none d-lg-flex row elsa-navbar">
              <div class="col pr-0">
                <nav
                  class="bg-dark d-flex flex-row w-100 h-100 justify-content-start align-items-center text-white pl-5 pr-0">
                  <div class="nav-item-container px-3" routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                    <a [routerLink]="Paths.Homepage | page | localize" [title]="PathNames[Paths.Homepage] | translate"
                       appEmptyTitle
                       class="nav-item elsa-title-font">{{PathNames[Paths.Homepage] | translate}}</a>
                  </div>
                  <div class="nav-item-container px-3" (mouseover)="onMouseOverOnAboutUsLabel()"
                       (mouseleave)="onMouseLeaveFromAboutUsLabel()" [ngbPopover]="popAboutUsContent"
                       [popoverClass]="'aboutUsPopover'"
                       routerLinkActive="active">
                    <a [routerLink]="Paths.AboutUs | page | localize" [title]="PathNames[Paths.AboutUs] | translate"
                       appEmptyTitle
                       class="nav-item elsa-title-font">{{PathNames[Paths.AboutUs]| translate}}</a>
                    <ng-template #popAboutUsContent>
                      <div (mouseover)="onMouseOverOnAboutUs()" (mouseleave)="onMouseLeaveFromAboutUs()"
                           class="popover-container">
                        <div class="popover-box">
                          <h5 class="elsa-title-font"></h5>
                          <ul class="">
                            <li><a class="" [routerLink]="Paths.AboutUs | page | localize"
                                   [fragment]="'label_fragment_history' | translate"
                                   appEmptyTitle>{{'label_history' | translate | uppercase}}</a></li>
                            <li><a class="" [routerLink]="Paths.AboutUs | page | localize"
                                   [fragment]="'label_fragment_certifications' | translate"
                                   appEmptyTitle>{{'label_certifications' | translate | uppercase}}</a></li>
                            <li><a class="" [routerLink]="Paths.AboutUs | page | localize"
                                   [fragment]="'label_fragment_ethic' | translate"
                                   appEmptyTitle>{{'label_ethic' | translate | uppercase}}</a></li>
                          </ul>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="nav-item-container px-3" (mouseover)="onMouseOverOnProductLabel()"
                       (mouseleave)="onMouseLeaveFromProductLabel()" [ngbPopover]="popProductsContent"
                       [popoverClass]="'productsPopover'"
                       routerLinkActive="active">
                    <a [routerLink]="Paths.Products | page| localize" [title]="PathNames[Paths.Products] | translate"
                       appEmptyTitle
                       class="nav-item elsa-title-font">{{PathNames[Paths.Products] | translate}}</a>
                    <ng-template #popProductsContent>
                      <div (mouseover)="onMouseOverOnProducts()" (mouseleave)="onMouseLeaveFromProducts()"
                           class="popover-container">
                        <div class="popover-box">
                          <h5 class="elsa-title-font">{{'label_boring_machines'|translate}}</h5>
                          <ul>
                            <li *ngFor="let product of products"><a class="" [title]="product.name"
                                                                    [routerLink]="Paths.Product | page:[product.name_slug] | localize"
                                                                    appEmptyTitle>{{product.name}}</a></li>
                          </ul>
                        </div>
                        <div class="popover-spacer"></div>
                        <div class="popover-box">
                          <h5 class="elsa-title-font">{{'label_kit_and_accessories' | translate}}</h5>
                          <ul class="">
                            <li *ngFor="let kit of kits"><a class="" [title]="kit.name"
                                                            [routerLink]="Paths.Kit | page:[kit.name_slug] | localize"
                                                            appEmptyTitle>{{kit.name}}</a></li>
                            <li><a class="" [routerLink]="Paths.Products | page | localize"
                                   [fragment]="'label_fragment_accessories' | translate"
                                   appEmptyTitle>{{'label_fragment_accessories' | translate | uppercase}}</a></li>
                          </ul>
                        </div>
                        <div class="popover-spacer"></div>
                        <div class="popover-box">
                          <h5 class="elsa-title-font">{{'label_services' | translate}}</h5>
                          <ul class="">
                            <li><a class="" [routerLink]="Paths.Products | page | localize"
                                   [fragment]="'warranty'"
                                   appEmptyTitle>{{'label_warranty_exstension' | translate | uppercase}}</a></li>
                            <li><a class="" [href]="'operational_lease_page_url' | translate | safeUrl"
                                   appEmptyTitle>{{'label_operational_lease' | translate | uppercase}}</a></li>
                            <li><a class="" [href]="'link_training_program' | translate | safeUrl"
                                   appEmptyTitle>{{'label_training_program' | translate | uppercase}}</a></li>
                            <li><a class="" [href]="'xtra_fan_contest_page_url' | translate | safeUrl" target="_blank"
                                   appEmptyTitle>{{'label_xtra_fan_contest' | translate | uppercase}}</a></li>
                          </ul>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="nav-item-container px-3" (mouseover)="onMouseOverOnContactsLabel()"
                       (mouseleave)="onMouseLeaveFromContactsLabel()" [ngbPopover]="popContactsContent"
                       [popoverClass]="'contactsPopover'"
                       routerLinkActive="active">
                    <a [routerLink]="Paths.Elsa | page  | localize" [title]="PathNames[Paths.Elsa] | translate"
                       appEmptyTitle
                       class="nav-item elsa-title-font">{{PathNames[Paths.Elsa] | translate}}</a>
                    <ng-template #popContactsContent>
                      <div (mouseover)="onMouseOverOnContacts()" (mouseleave)="onMouseLeaveFromContacts()"
                           class="popover-container">
                        <div class="popover-box">
                          <h5 class="elsa-title-font"></h5>
                          <ul class="">
                            <li><a class="" [routerLink]="Paths.Elsa | page | localize"
                                   [fragment]="'label_fragment_resellers' | translate"
                                   appEmptyTitle>{{'label_resellers' | translate | uppercase}}</a></li>
                            <li><a class="" [routerLink]="Paths.Elsa | page | localize"
                                   [fragment]="'label_fragment_become_reseller' | translate"
                                   appEmptyTitle>{{'label_become_reseller' | translate | uppercase}}</a></li>
                            <li><a class="" [routerLink]="Paths.Elsa | page | localize"
                                   [fragment]="'label_fragment_info_request' | translate"
                                   appEmptyTitle>{{'label_info_request' | translate | uppercase}}</a></li>
                          </ul>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                  <div class="nav-item-container px-3" routerLinkActive="active">
                    <a [routerLink]="Paths.News | page  | localize" [title]="PathNames[Paths.News] | translate"
                       appEmptyTitle
                       class="nav-item elsa-title-font">{{PathNames[Paths.News] | translate}}</a>
                  </div>
                  <app-search class="h-100 ml-auto"></app-search>
                </nav>
              </div>
              <div class="col-15 pl-0">
                <div class="bg-danger d-flex justify-content-center align-items-center h-100">
                  <a class="text-white elsa-title-font quotation-button elsa-transition text-center"
                     [title]="'label_quotation' | translate" appEmptyTitle
                     (click)="openQuotation()">{{'label_quotation' | translate}}</a>
                </div>
              </div>
            </div>
            <app-breadcrumb></app-breadcrumb>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="navbar-spacer"></div>
