import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {Config} from '../../../config/config';
import {HttpResponse} from '@angular/common/http';
import {shareReplay} from 'rxjs/operators';
import {TransferStateService} from './transfer-state.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private contacts$;

  constructor(private apiService: ApiService, private transferState: TransferStateService) {
  }

  /**
   * Api translatable
   */
  public get(): Observable<HttpResponse<any>> {
    if (!this.contacts$) {
      this.contacts$ =
        this.apiService.translateApi(
          (l) => this.apiService.get(Config.ENDPOINTS.contacts.all(), undefined, undefined, l)
        ).pipe(shareReplay(1));
    }
    return this.contacts$;
  }

  /**
   * Api translatable
   */
  public elsaInTheWorld(): Observable<HttpResponse<any>> {
    return this.transferState.runApi(
      'contacts-elsa-in-the-world',
      this.apiService.translateApi((l) => this.apiService.get(Config.ENDPOINTS.contacts.world(), undefined, undefined, l))
    );
  }

  public getWithLocale(locale): Observable<HttpResponse<any>> {
    if (!this.contacts$) {
      this.contacts$ = this.apiService.get(Config.ENDPOINTS.contacts.all(), undefined, undefined, locale).pipe(shareReplay(1));
    }
    return this.contacts$;
  }

  public elsaInTheWorldWithLocale(locale): Observable<HttpResponse<any>> {
    return this.transferState.runApi(
      'contacts-elsa-in-the-world',
      this.apiService.get(Config.ENDPOINTS.contacts.world(), undefined, undefined, locale)
    );
  }
}
