import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PathNames, Paths } from '../../../config/config';
import { ContactsService } from '../../providers/services/contacts.service';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { FormService } from '../../providers/services/form.service';
import { ToasterService } from '../../providers/services/toaster.service';
import { FilesService } from '../../providers/services/files.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {

  public schemaContact;
  private readonly subscriptions: Subscription = new Subscription();
  public readonly Paths = Paths;
  public readonly PathNames = PathNames;
  public contacts;
  public newsletterForm;
  public companyIsOpen = false;
  public legalIsOpen = false;
  public catalog$: Observable<string>;
  public caseStudy$: Observable<string>;

  constructor(
    private contactsService: ContactsService, private fb: FormBuilder, private formsService: FormService,
    private alertService: ToasterService, private filesService: FilesService, private translateService: TranslateService
  ) {
    this.newsletterForm = this.fb.group({
      email: '',
      privacy: false
    });

    this.subscriptions.add(this.contactsService.get()
      .pipe(map(res => res.body.data))
      .subscribe((contacts) => {
        this.contacts = contacts;
        this.setSchema(this.contacts);
      }));

    this.catalog$ = this.filesService.getLocalizedCatalog(this.translateService.currentLang);
    this.caseStudy$ = this.filesService.getLocalizedCaseStudy(this.translateService.currentLang);
    this.subscriptions.add(this.translateService.onLangChange.subscribe(newLangEvent => {
      this.catalog$ = this.filesService.getLocalizedCatalog(newLangEvent.lang);
      this.caseStudy$ = this.filesService.getLocalizedCaseStudy(newLangEvent.lang);
    }));
  }

  onSubmitNewsletter() {
    this.formsService.newsletterSubscribe(this.newsletterForm).subscribe(({ body }) => {
      this.newsletterForm.patchValue({
        email: '',
        privacy: false
      });
      this.alertService.success(body.meta.message);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSchema({ default: { position, phone, email } }) {
    this.schemaContact = {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.elsasrl.com',
      name: 'Elsa srl',
      contactPoint: {
        '@type': 'ContactPoint',
        telephone: phone.number,
        email: email.email,
        contactType: 'Customer service'
      },
      address: {
        '@type': 'PostalAddress',
        addressLocality: position.address_line_2,
        postalCode: position.cap,
        streetAddress: position.address_line_1

      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: position.latitude,
        longitude: position.longitude
      }
    };
  }
}
