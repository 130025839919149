import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private subject: BehaviorSubject<UrlItem[]> = new BehaviorSubject<[]>([]);

  constructor() {
  }

  public get observableUrls() {
    return this.subject.asObservable();
  }

  public next(items: BreadcrumbUrls) {
    this.subject.next(items.getUrls());
  }

  public addBreadcrumbItems(item: UrlItem | UrlItem[]) {
    let current = this.subject.getValue();
    if (Array.isArray(item)) {
      current = [...current, ...item];
    } else {
      current.push(item);
    }
    this.subject.next(current);
  }

  public reset() {
    this.subject.next([]);
  }
}

export class BreadcrumbUrls {

  private urls = [];

  static get instance() {
    return new BreadcrumbUrls();
  }

  constructor() {
  }

  addUrl(item: UrlItem) {
    this.urls.push(item);
    return this;
  }

  getUrls() {
    return this.urls;
  }
}

interface UrlItem {
  link: string | number;
  label: string;
  args?: any[];
}
