import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreTranslateService {

  private languagesSubject = new BehaviorSubject([]);
  private loading = true;

  constructor() {
  }

  getLanguages() {
    let observer = this.languagesSubject.asObservable();
    if (!this.loading) {
      observer = observer.pipe(first());
    }
    return observer;
  }

  setLanguages(languages: any) {
    this.languagesSubject.next(languages);
    this.languagesSubject.observers.forEach(el => el.complete());
  }
}
