<div class="container">
  <div class="row">
    <div class="col-60">
      <div class="d-flex flex-column justify-content-center align-items-center">
        <a title="Elsa" appEmptyTitle (click)="modalActive.close()" class="my-3">
          <img src="assets/icons/icon_close_black.svg" width="60" alt="Elsa srl">
        </a>
      </div>
    </div>
    <div class="col-60">
      <div class="d-flex flex-column align-items-center w-100">
        <span class="quotation-title elsa-title-font">{{'form_quotation_info' | translate}}</span>
        <span class="quotation-subtitle">{{'form_quotation_info_subtitle' |translate}}</span>
      </div>
    </div>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()" class="col-60 col-md-40 offset-md-10"
          id="g-quotation-form">
      <div class="form-group">
        <input type="text" formControlName="company_name" class="form-control form-control-elsa"
               name="g-quotation-form-company"
               [placeholder]="('form_company_name' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('company_name')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <input type="text" formControlName="name" class="form-control form-control-elsa" name="g-quotation-form-name"
               [placeholder]="('form_name' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('name')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <input formControlName="surname" type="text" class="form-control form-control-elsa" name="g-quotation-form-surname"
               [placeholder]="('form_surname' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('surname')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <input type="text" formControlName="email" class="form-control form-control-elsa" name="g-quotation-form-email"
               [placeholder]="('form_email' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('email')"></app-form-control-validator>
      </div>
      <div class="form-group d-flex">
        <select class="form-control form-control-elsa w-40" formControlName="phone_prefix">
          <option value="">+</option>
          <option *ngFor='let prefix of PhonePrefixes' value="{{prefix}}">{{prefix}}</option>
        </select>
        <input type="text" formControlName="phone" class="form-control form-control-elsa" name="g-quotation-form-phone"
               [placeholder]="('form_phone' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('phone')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <input type="text" formControlName="address" class="form-control form-control-elsa"
               name="g-quotation-form-address"
               [placeholder]="('form_address' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('address')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <input type="text" formControlName="city" class="form-control form-control-elsa" name="g-quotation-form-city"
               [placeholder]="('form_city' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('city')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <input type="text" formControlName="cap" class="form-control form-control-elsa" name="g-quotation-form-cap"
               [placeholder]="('form_cap' | translate)+'*'">
        <app-form-control-validator [elsaControl]="formGroup.get('cap')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <select formControlName="country" class="form-control form-control-elsa" name="g-quotation-form-country">
          <option value="" [label]="('form_state' | translate)+'*'"></option>
          <option *ngFor='let nation of NationsList' value="{{nation.value}}">{{nation.label}}</option>
        </select>
        <app-form-control-validator [elsaControl]="formGroup.get('country')"></app-form-control-validator>
      </div>
      <div class="form-group">
        <select formControlName="model_name" class="custom-select form-control form-control-elsa"
                name="g-quotation-form-model">
          <option class="form-option-elsa" value="">{{'form_choose_kit_or_product'| translate}}*</option>
          <option [value]="Models.PRODUCT">{{'label_product' | translate}}</option>
          <option [value]="Models.KIT">{{'label_kit' | translate}}</option>
          <option [value]="Models.ACCESSORIES">{{'label_accessory' | translate}}</option>
        </select>
        <app-form-control-validator [elsaControl]="formGroup.get('model_name')"></app-form-control-validator>
      </div>

      <div [ngSwitch]="formGroup.get('model_name').value">

        <!--PRODOTTO-->
        <div class="form-group" *ngSwitchCase="Models.PRODUCT">
          <select formControlName="model_slug" class="custom-select form-control form-control-elsa"
                  name="g-quotation-form-model">
            <option class="form-option-elsa" value="">{{'form_select_product' | translate}}*</option>
            <option *ngFor="let product of products" [value]="product.name_slug">{{product.name}}</option>
          </select>
          <app-form-control-validator [elsaControl]="formGroup.get('model_slug')"></app-form-control-validator>
        </div>

        <!--KIT-->
        <div class="form-group" *ngSwitchCase="Models.KIT">
          <select formControlName="model_slug" class="custom-select form-control form-control-elsa"
                  name="g-quotation-form-model">
            <option class="form-option-elsa" value="">{{'form_select_kit' |translate}}*</option>
            <option *ngFor="let serie of series" [value]="serie.name_slug">{{serie.name}}</option>
          </select>
          <app-form-control-validator [elsaControl]="formGroup.get('model_slug')"></app-form-control-validator>
        </div>

        <!--ACCESSORIO-->
        <div class="form-group" *ngSwitchCase="Models.ACCESSORIES">
          <select formControlName="model_slug" class="custom-select form-control form-control-elsa"
                  name="g-quotation-form-model">
            <option class="form-option-elsa" value="">{{'form_select_accessory' | translate}}*</option>
            <option *ngFor="let accessory of accessories" [value]="accessory.id">{{accessory.name}}</option>
          </select>
          <app-form-control-validator [elsaControl]="formGroup.get('model_slug')"></app-form-control-validator>
        </div>

      </div>

      <div class="form-group">
        <textarea type="text" formControlName="text" class="form-control form-text-area-elsa"
                  name="g-quotation-form-text"
                  [placeholder]="'form_text' | translate" rows="5"></textarea>
        <app-form-control-validator [elsaControl]="formGroup.get('text')"></app-form-control-validator>
      </div>
      <div
        class="custom-control custom-checkbox align-self-baseline align-self-md-center mt-1 container-checkbox">
        <input class="custom-control-input newsletter-form-privacy-checkbox" type="checkbox"
               formControlName="privacy" id="privacy_quotation" name="g-quotation-form-privacy">
        <label class="custom-control-label font-weight-light newsletter-form-privacy mb-0"
               for="privacy_quotation">{{'label_privacy'|translate}}&nbsp;<a target="_blank" class="text-link-decorated"
                                                                             [routerLink]="Paths.Privacy|page|localize">({{'path_name_privacy'|translate}}
          )</a></label>
        <app-form-control-validator
          [elsaControl]="formGroup.get('privacy')"></app-form-control-validator>
      </div>
      <div
        class="custom-control custom-checkbox align-self-baseline align-self-md-center mt-1 container-checkbox">
        <input class="custom-control-input newsletter-form-privacy-checkbox" type="checkbox"
               formControlName="marketing" id="marketing_quotation" name="g-quotation-form-marketing">
        <label class="custom-control-label font-weight-light newsletter-form-privacy mb-0"
               for="marketing_quotation">{{'label_marketing'|translate}}</label>
        <app-form-control-validator
          [elsaControl]="formGroup.get('marketing')"></app-form-control-validator>
      </div>
      <div class="mb-4">
        <button class="text-uppercase btn btn-gray btn-quotation w-100"
                type="submit">{{'label_quotation' | translate}}</button>
      </div>
    </form>
  </div>
</div>
