import {Component, ElementRef, HostListener, Inject, Input, OnInit} from '@angular/core';
import {PathNames, Paths} from '../../../config/config';
import {trigger} from '@angular/animations';
import {easeInOut, easeInOutHorizontal} from '../../animations/animations';
import {DOCUMENT} from '@angular/common';
import {UtilsService} from '../../providers/services/utils.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('products', easeInOut),
    trigger('menu', easeInOutHorizontal)
  ]
})
export class MenuComponent implements OnInit {

  private privateShowItem;

  public get showItem() {
    return this.privateShowItem;
  }

  public set showItem(value) {
    value ? this.lockBody() : this.unlockBody();
    this.privateShowItem = value;
  }

  @Input() public products;
  @Input() public kits;
  public productsIsCollapsed;
  public readonly Paths = Paths;
  public readonly PathNames = PathNames;

  constructor(private eRef: ElementRef, @Inject(DOCUMENT) private document, private utilsService: UtilsService) {
  }

  ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showItem = false;
    }
  }


  private lockBody() {
    const body = this.body;
    if (body) {
      body.style.overflow = 'hidden';
    }
  }

  private unlockBody() {
    const body = this.body;
    if (body) {
      body.style.overflow = 'scroll';
    }
  }

  private get body() {
    if (this.utilsService.isPlatformBrowser) {
      const body = this.document.getElementsByTagName('body');
      return body ? body[0] : undefined;
    }
  }
}
