import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Config} from '../../../config/config';

@Component({
  selector: 'app-form-control-validator',
  templateUrl: './form-control-validator.component.html',
  styleUrls: ['./form-control-validator.component.scss']
})
export class FormControlValidatorComponent implements OnInit {

  @Input() elsaControl: AbstractControl;

  public readonly ELSA_VALIDATION_FORMS_KEY = Config.ELSA_VALIDATION_FORMS_KEY;

  constructor() {
  }

  ngOnInit() {
  }

}
