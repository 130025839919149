/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-link-in-evidence.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./header-link-in-evidence.component";
var styles_HeaderLinkInEvidenceComponent = [i0.styles];
var RenderType_HeaderLinkInEvidenceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderLinkInEvidenceComponent, data: {} });
export { RenderType_HeaderLinkInEvidenceComponent as RenderType_HeaderLinkInEvidenceComponent };
function View_HeaderLinkInEvidenceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-60 bg-gray p-2 align-items-center"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [["class", "elsa-title-font text-center text-uppercase m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("label_header_link_in_evidence")); _ck(_v, 3, 0, currVal_0); }); }
export function View_HeaderLinkInEvidenceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderLinkInEvidenceComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEnabled; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HeaderLinkInEvidenceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-link-in-evidence", [], null, null, null, View_HeaderLinkInEvidenceComponent_0, RenderType_HeaderLinkInEvidenceComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderLinkInEvidenceComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderLinkInEvidenceComponentNgFactory = i1.ɵccf("app-header-link-in-evidence", i4.HeaderLinkInEvidenceComponent, View_HeaderLinkInEvidenceComponent_Host_0, {}, {}, []);
export { HeaderLinkInEvidenceComponentNgFactory as HeaderLinkInEvidenceComponentNgFactory };
