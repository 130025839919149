import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
export class SeoService {
    constructor(meta, title, translateService, dom) {
        this.meta = meta;
        this.title = title;
        this.translateService = translateService;
        this.dom = dom;
    }
    setTags(title, description = '', image = '', links = null) {
        const currentLanguage = this.translateService.currentLang;
        this.dom.documentElement.lang = currentLanguage;
        const currentUrl = this.getCurrentUrl(currentLanguage, links);
        title = `${title} | Elsa Srl`;
        this.title.setTitle(title);
        const metaTags = [
            {
                name: 'description',
                content: description
            },
            {
                name: 'twitter:card',
                content: 'summary'
            },
            {
                name: 'twitter:description',
                content: description
            },
            {
                name: 'twitter:image',
                content: image
            },
            {
                name: 'twitter:site',
                content: '@Elsa_srl'
            },
            {
                name: 'twitter:title',
                content: title
            },
            {
                property: 'og:description',
                content: description
            },
            {
                property: 'og:image',
                content: image
            },
            {
                property: 'og:locale',
                content: currentLanguage
            },
            {
                property: 'og:site_name',
                content: 'Elsa srl'
            },
            {
                property: 'og:title',
                content: title
            },
            {
                property: 'og:type',
                content: 'website'
            },
            {
                property: 'og:url',
                content: currentUrl
            }
        ];
        this.updateGenericMeta(metaTags);
        this.setItempropMetaTags(description, image, title);
        if (links) {
            Object.keys(links).forEach(lang => {
                this.setLinkTag(lang, links[lang]);
            });
            this.setLinkTag('x-default', links.it);
        }
        this.setCanonicalUrl(currentUrl);
    }
    getCurrentUrl(currentLanguage, links) {
        if (links && Object.keys(links).includes(currentLanguage)) {
            return links[currentLanguage];
        }
        const currentUrl = new URL(this.dom.URL);
        currentUrl.searchParams.sort();
        return currentUrl.toString();
    }
    setItempropMetaTags(description, image, title) {
        this.setItempropTag('description', description);
        this.setItempropTag('image', image);
        this.setItempropTag('name', title);
    }
    setItempropTag(tagName, tagValue) {
        const tag = this.meta.getTag(`itemprop=${tagName}`);
        if (tag) {
            tag.content = tagValue;
        }
        else {
            this.meta.addTag({
                itemprop: tagName,
                content: tagValue
            });
        }
    }
    setLinkTag(lang, url) {
        let link = this.dom.querySelector(`link[rel=alternate][hreflang=${lang}]`);
        if (!link) {
            link = HTMLLinkElement = this.dom.createElement('link');
            link.setAttribute('rel', 'alternate');
            this.dom.head.appendChild(link);
        }
        link.setAttribute('hreflang', lang);
        link.setAttribute('href', url);
    }
    setCanonicalUrl(canonicalUrl) {
        let linkElement = this.dom.querySelector('link[rel=canonical]');
        if (!linkElement) {
            linkElement = this.dom.createElement('link');
            linkElement.setAttribute('rel', 'canonical');
            this.dom.head.appendChild(linkElement);
        }
        linkElement.setAttribute('href', canonicalUrl);
    }
    updateGenericMeta(metaTags) {
        metaTags.forEach(metaTag => {
            if (metaTag.content !== '') {
                this.meta.updateTag(metaTag);
            }
            else {
                this.meta.removeTag(`property='${metaTag.property}'`);
            }
        });
    }
}
SeoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.ɵɵinject(i1.Meta), i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.DOCUMENT)); }, token: SeoService, providedIn: "root" });
