import {TranslateLoader, TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Config} from '../../config/config';
import {LocalizeParser, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {Location} from '@angular/common';
import {Routes} from '@angular/router';
import {StoreTranslateService} from '../providers/services/store-translate.service';
import {map, tap} from 'rxjs/operators';
import {makeStateKey, TransferState} from '@angular/platform-browser';

class CustomTranslateLoader implements TranslateLoader {

  constructor(private http: HttpClient, private transferState: TransferState) {
  }

  getTranslation(lang: string): Observable<any> {
    const translationKey = makeStateKey(`translation_${lang}`);
    if (!this.transferState.hasKey(translationKey)) {
      return this.http.get<any>(Config.ENDPOINTS.translations.language(lang))
        .pipe(map(res => res.data.translations), tap(data => this.transferState.set(translationKey, data)));
    }
    const language = this.transferState.get(translationKey, {});
    this.transferState.remove(translationKey);
    return of(language);
  }

}

class CustomRouterHttpLoader extends LocalizeParser {

  private localTranslateService: TranslateService;

  constructor(
    translate: TranslateService,
    location: Location,
    settings: LocalizeRouterSettings,
    private storeTranslateService: StoreTranslateService,
    private http: HttpClient,
    private transferState: TransferState
  ) {
    super(translate, location, settings);
    this.localTranslateService = translate;
  }

  async load(routes: Routes): Promise<any> {
    const translationKey = makeStateKey(`translations`);
    return new Promise((resolve: any) => {

      if (this.transferState.hasKey(translationKey)) {
        this.setData(this.transferState.get(translationKey, {}));
        this.transferState.remove(translationKey);
        this.init(routes).then(resolve);
        return;
      }

      this.http.get(Config.ENDPOINTS.translations.languages())
        // @ts-ignore
        .subscribe(({data}) => {
          this.transferState.set(translationKey, data);
          this.setData(data);
          this.init(routes).then(resolve);
        });
    });
  }

  private setData(data) {
    this.locales = Object.keys(data.locales);
    this.storeTranslateService.setLanguages(data.locales);
    this.localTranslateService.addLangs(this.locales);
    this.prefix = data.prefix || '';
    this.escapePrefix = data.escapePrefix || '';
  }
}

export const CustomTranslateLoaderFactory = (http: HttpClient, transferState: TransferState) =>
  new CustomTranslateLoader(http, transferState);

export const LocalizeRouterHttpLoaderFactory = (translate, location, settings, storeTranslate, http, transferState: TransferState) =>
  new CustomRouterHttpLoader(translate, location, settings, storeTranslate, http, transferState);
