import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Config, Paths } from '../../../config/config';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private renderer2: Renderer2;

  constructor(
    private localizeRouterTranslateService: LocalizeRouterService, @Inject(PLATFORM_ID) private platformId,
    rendererFactory: RendererFactory2
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  get isPlatformBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  getLink(namePage: Paths, ...values): string | any[] {
    return Config.PATHS_VALUE[namePage](values && values.length ? values[0] : void 0).join('/');
  }

  openLink(link) {
    const a = this.renderer2.createElement('a');
    this.renderer2.setAttribute(a, 'target', '_blank');
    this.renderer2.setAttribute(a, 'href', link);
    a.click();
  }

}
