import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from './api.service';
import {Config} from '../../../config/config';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  constructor(private apiService: ApiService) {
  }

  public getEthic(): Observable<string> {
    return this.getFile(Config.STATIC_FILES.ethic);
  }

  public getOrganizationAct(): Observable<string> {
    return this.getFile(Config.STATIC_FILES.organizationAct);
  }

  public getOrganizationModel(): Observable<string> {
    return this.getFile(Config.STATIC_FILES.organizationModel);
  }

  public getLocalizedCatalog(locale): Observable<string> {
    return this.getLocalizedFile(Config.STATIC_FILES.catalog, locale);
  }

  public getLocalizedCaseStudy(locale): Observable<string> {
    return this.getLocalizedFile(Config.STATIC_FILES.caseStudy, locale);
  }

  private getFile(slug): Observable<string> {
    return this.apiService.get(Config.ENDPOINTS.files.file(slug)).pipe(
      map(({body}) => body.path ? body.path : null),
      catchError(() => of(null))
    );
  }

  private getLocalizedFile(slug, locale): Observable<string> {
    const localizedSlug = locale ? slug + '-' + locale : slug;
    return this.getFile(localizedSlug);
  }
}
