import * as i0 from "@angular/core";
import * as i1 from "./utils.service";
export class AnalyticsService {
    constructor(utilsService) {
        this.utilsService = utilsService;
    }
    pushEventToDataLayer(event) {
        if (this.utilsService.isPlatformBrowser) {
            const dataLayer = window.dataLayer;
            dataLayer.push(event);
        }
    }
    formSubmission(type) {
        this.pushEventToDataLayer({
            event: 'formSubmission',
            formType: type
        });
    }
}
AnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AnalyticsService_Factory() { return new AnalyticsService(i0.ɵɵinject(i1.UtilsService)); }, token: AnalyticsService, providedIn: "root" });
