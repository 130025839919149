import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './navbar/navbar.component';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../providers/pipes/pipes.module';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbModalModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {SearchComponent} from './search/search.component';
import {MenuComponent} from './menu/menu.component';
import {SocialComponent} from './social/social.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {ModalsModule} from '../components/modals/modals.module';
import {ButtonGoUpComponent} from './button-go-up/button-go-up.component';
import {DirectivesModule} from '../providers/directives/directives.module';
import {WidgetsModule} from '../widgets/widgets.module';
import {NewsPopupDialogComponent} from './news-popup-dialog/news-popup-dialog.component';
import {LinkPopupDialogComponent} from './link-popup-dialog/link-popup-dialog.component';
import { HeaderLinkInEvidenceComponent } from './navbar/header-link-in-evidence/header-link-in-evidence.component';

@NgModule({
  declarations: [NavbarComponent, FooterComponent, SearchComponent, MenuComponent, SocialComponent,
    BreadcrumbComponent, ButtonGoUpComponent, NewsPopupDialogComponent, LinkPopupDialogComponent, HeaderLinkInEvidenceComponent],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    LocalizeRouterModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    NgbDropdownModule,
    ModalsModule,
    NgbModalModule,
    DirectivesModule,
    WidgetsModule,
    NgbPopoverModule
  ],
  exports: [NavbarComponent, FooterComponent, BreadcrumbComponent, ButtonGoUpComponent, NewsPopupDialogComponent,
    LinkPopupDialogComponent]
})
export class LayoutModule {
}
