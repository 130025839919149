import {Component, ElementRef, HostListener, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Paths} from '../../../config/config';
import {Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';
import {UtilsService} from '../../providers/services/utils.service';
import {debounceTime, filter} from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy {

  public showItem;
  public searchControl = new FormControl();
  private readonly subscriptions: Subscription = new Subscription();

  constructor(private eRef: ElementRef, private router: Router, private localizeRouter: LocalizeRouterService, private utilsService: UtilsService) {
    this.subscriptions.add(
      this.searchControl.valueChanges.pipe(filter(item => item), debounceTime(500)).subscribe((term: string) => {
        this.router.navigateByUrl(this.localizeRouter.translateRoute(this.utilsService.getLink(Paths.Search, [term.replace(/[\/+\&+\?+]/gm, ' ').trim()])) as any);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showItem = false;
    }
  }
}
