<div class="d-lg-none h-100">
  <div class="position-relative h-100">
    <a (click)="showItem = !showItem" class="d-flex flex-row align-items-center h-100" title="Elsa Srl"
       appEmptyTitle
       [ngClass]="{'menu-active': showItem}">
      <img alt="Elsa Srl" src="assets/icons/icon_menu.svg" class="menu-img" height="16">
      <div class="menu-spacer menu-animation"></div>
    </a>
    <nav [@menu]="showItem ? 'visible' : 'hidden'" class="menu bg-success">
      <a [routerLink]="Paths.Homepage | page | localize " class="elsa-title-font menu-item"
         [title]="PathNames[Paths.Homepage] | translate" appEmptyTitle
         (click)="showItem = false">{{PathNames[Paths.Homepage] | translate}}</a>
      <a [routerLink]="Paths.AboutUs | page  | localize" class="elsa-title-font menu-item"
         [title]="PathNames[Paths.AboutUs] | translate" appEmptyTitle
         (click)="showItem = false">{{PathNames[Paths.AboutUs] | translate}}</a>
      <div class="w-100 d-flex flex-row justify-content-end">
        <a [routerLink]="Paths.Products | page | localize" class="elsa-title-font menu-item menu-item-products"
           [title]="PathNames[Paths.Products] | translate" appEmptyTitle
           (click)="showItem = false">{{PathNames[Paths.Products] | translate}}</a>
        <a (click)="productsIsCollapsed =!productsIsCollapsed" class="button-products" title="Elsa Srl"
           appEmptyTitle>
          <img class="elsa-transition" src="assets/icons/icon_down_arrow_small_white.svg" height="10"
               alt="Elsa Srl"
               [class.products-open]="productsIsCollapsed">
        </a>
      </div>
      <div [@products]="productsIsCollapsed ? 'visible' : 'hidden'"
           class="d-flex flex-column justify-content-end">
        <a class="menu-item text-right menu-item-small" [title]="'label_boring_machines' | translate"
           [routerLink]="Paths.Products | page | localize"
           (click)="showItem = false" appEmptyTitle>{{'label_boring_machines' | translate}}</a>
        <a class="menu-item text-right menu-item-small" [title]="'label_kit' | translate"
           [routerLink]="Paths.Products | page | localize" [fragment]="'label_fragment_kit' | translate"
           (click)="showItem = false" appEmptyTitle>{{'label_kit' | translate}}</a>
        <a class="menu-item text-right menu-item-small" [title]="'label_fragment_accessories' | translate"
           [routerLink]="Paths.Products | page | localize" [fragment]="'label_fragment_accessories' | translate"
           (click)="showItem = false" appEmptyTitle>{{'label_fragment_accessories' | translate}}</a>
        <a class="menu-item text-right menu-item-small" [title]="'label_warranty_exstension' | translate"
           [routerLink]="Paths.Products | page | localize" [fragment]="'warranty'"
           (click)="showItem = false" appEmptyTitle>{{'label_warranty_exstension' | translate}}</a>
        <a class="menu-item text-right menu-item-small" [title]="'label_operational_lease' | translate"
           [href]="'operational_lease_page_url' | translate | safeUrl"
           appEmptyTitle>{{'label_operational_lease' | translate}}</a>
        <a class="menu-item text-right menu-item-small" [title]="'label_training_program' | translate"
           [href]="'link_training_program' | translate | safeUrl"
           appEmptyTitle>{{'label_training_program' | translate}}</a>
      </div>
      <a [routerLink]="Paths.Elsa | page  | localize" class="elsa-title-font menu-item"
         [title]="PathNames[Paths.Elsa] | translate" appEmptyTitle
         (click)="showItem = false">{{PathNames[Paths.Elsa] | translate}}</a>
      <a [routerLink]="Paths.News | page | localize " class="elsa-title-font menu-item"
         [title]="PathNames[Paths.News] | translate" appEmptyTitle
         (click)="showItem = false">{{PathNames[Paths.News] | translate}}</a>
    </nav>
  </div>
</div>
