import { Config } from '../../../config/config';
import { shareReplay } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./transfer-state.service";
export class ContactsService {
    constructor(apiService, transferState) {
        this.apiService = apiService;
        this.transferState = transferState;
    }
    /**
     * Api translatable
     */
    get() {
        if (!this.contacts$) {
            this.contacts$ =
                this.apiService.translateApi((l) => this.apiService.get(Config.ENDPOINTS.contacts.all(), undefined, undefined, l)).pipe(shareReplay(1));
        }
        return this.contacts$;
    }
    /**
     * Api translatable
     */
    elsaInTheWorld() {
        return this.transferState.runApi('contacts-elsa-in-the-world', this.apiService.translateApi((l) => this.apiService.get(Config.ENDPOINTS.contacts.world(), undefined, undefined, l)));
    }
    getWithLocale(locale) {
        if (!this.contacts$) {
            this.contacts$ = this.apiService.get(Config.ENDPOINTS.contacts.all(), undefined, undefined, locale).pipe(shareReplay(1));
        }
        return this.contacts$;
    }
    elsaInTheWorldWithLocale(locale) {
        return this.transferState.runApi('contacts-elsa-in-the-world', this.apiService.get(Config.ENDPOINTS.contacts.world(), undefined, undefined, locale));
    }
}
ContactsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactsService_Factory() { return new ContactsService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.TransferStateService)); }, token: ContactsService, providedIn: "root" });
