import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class StoreTranslateService {
    constructor() {
        this.languagesSubject = new BehaviorSubject([]);
        this.loading = true;
    }
    getLanguages() {
        let observer = this.languagesSubject.asObservable();
        if (!this.loading) {
            observer = observer.pipe(first());
        }
        return observer;
    }
    setLanguages(languages) {
        this.languagesSubject.next(languages);
        this.languagesSubject.observers.forEach(el => el.complete());
    }
}
StoreTranslateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreTranslateService_Factory() { return new StoreTranslateService(); }, token: StoreTranslateService, providedIn: "root" });
