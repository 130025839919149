import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class BreadcrumbService {
    constructor() {
        this.subject = new BehaviorSubject([]);
    }
    get observableUrls() {
        return this.subject.asObservable();
    }
    next(items) {
        this.subject.next(items.getUrls());
    }
    addBreadcrumbItems(item) {
        let current = this.subject.getValue();
        if (Array.isArray(item)) {
            current = [...current, ...item];
        }
        else {
            current.push(item);
        }
        this.subject.next(current);
    }
    reset() {
        this.subject.next([]);
    }
}
BreadcrumbService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BreadcrumbService_Factory() { return new BreadcrumbService(); }, token: BreadcrumbService, providedIn: "root" });
export class BreadcrumbUrls {
    constructor() {
        this.urls = [];
    }
    static get instance() {
        return new BreadcrumbUrls();
    }
    addUrl(item) {
        this.urls.push(item);
        return this;
    }
    getUrls() {
        return this.urls;
    }
}
