import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  public trackConsent(formId: string, emailFieldName: string, fullNameFieldName: string, privacyFieldName: string,
                      marketingFieldName: string) {
    // @ts-ignore
    const _iub = window._iub;
    if (_iub && _iub.cons_instructions) {
      _iub.cons_instructions.push(['submit',
        {
          form: {
            selector: formId,
            map: {
              subject: {
                email: emailFieldName,
                full_name: fullNameFieldName
              },
              preferences: {
                privacy: privacyFieldName,
                marketing: marketingFieldName
              }
            }
          }
        },
        {
          success(response) {
            console.log(response);
          },
          error(response) {
            console.log(response);
          }
        }
      ]);
    }
  }
}
