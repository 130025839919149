import {Component, OnDestroy} from '@angular/core';
import {UtilsService} from './providers/services/utils.service';
import {Paths} from '../config/config';
import {PagesService} from './providers/services/pages.service';
import {ActivatedRouteSnapshot, ActivationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {SeoService} from './providers/services/seo.service';

@Component({
  selector: 'app-elsa',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  public readonly Paths = Paths;
  public subscriptions: Subscription = new Subscription();

  constructor(
    public utilsService: UtilsService, private pagesService: PagesService, private router: Router, private seoService: SeoService
  ) {
    this.setSeo();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSeo() {
    this.subscriptions.add(
      this.router.events.pipe(
        filter(event => event instanceof ActivationEnd),
        filter((event: { snapshot: ActivatedRouteSnapshot }) => !!event.snapshot.data.page),
        switchMap((event: { snapshot: ActivatedRouteSnapshot }) => this.pagesService.getSeoPage(event.snapshot.data.page))
      ).subscribe(({body: {data: {seo, links}}}) => {
        this.seoService.setTags(seo.title, seo.description, seo.image_seo ? seo.image_seo.path : '', links);
      })
    );
  }

}
