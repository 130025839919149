/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-go-up.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../providers/directives/empty-title.directive";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./button-go-up.component";
import * as i5 from "../../providers/services/utils.service";
var styles_ButtonGoUpComponent = [i0.styles];
var RenderType_ButtonGoUpComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonGoUpComponent, data: {} });
export { RenderType_ButtonGoUpComponent as RenderType_ButtonGoUpComponent };
export function View_ButtonGoUpComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "col-60"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "position-absolute button-go-up-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "d-flex flex-column align-items-center  "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "a", [["appEmptyTitle", ""], ["class", "d-flex flex-column align-items-center translate"], ["title", "Back to top!"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goUp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.EmptyTitleDirective, [i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Back to top!"], ["class", "go-up-img"], ["src", "assets/icons/icon_navigation_up.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "back-to-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("label_go_up")); _ck(_v, 10, 0, currVal_0); }); }
export function View_ButtonGoUpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button-go-up", [], null, null, null, View_ButtonGoUpComponent_0, RenderType_ButtonGoUpComponent)), i1.ɵdid(1, 114688, null, 0, i4.ButtonGoUpComponent, [i5.UtilsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonGoUpComponentNgFactory = i1.ɵccf("app-button-go-up", i4.ButtonGoUpComponent, View_ButtonGoUpComponent_Host_0, {}, {}, []);
export { ButtonGoUpComponentNgFactory as ButtonGoUpComponentNgFactory };
