import * as i0 from "@angular/core";
export class ConsentService {
    trackConsent(formId, emailFieldName, fullNameFieldName, privacyFieldName, marketingFieldName) {
        // @ts-ignore
        const _iub = window._iub;
        if (_iub && _iub.cons_instructions) {
            _iub.cons_instructions.push(['submit',
                {
                    form: {
                        selector: formId,
                        map: {
                            subject: {
                                email: emailFieldName,
                                full_name: fullNameFieldName
                            },
                            preferences: {
                                privacy: privacyFieldName,
                                marketing: marketingFieldName
                            }
                        }
                    }
                },
                {
                    success(response) {
                        console.log(response);
                    },
                    error(response) {
                        console.log(response);
                    }
                }
            ]);
        }
    }
}
ConsentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsentService_Factory() { return new ConsentService(); }, token: ConsentService, providedIn: "root" });
