import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from './api.service';
import {TransferStateService} from './transfer-state.service';
import {Config} from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private apiService: ApiService, private transferState: TransferStateService) {
  }

  public getProductInEvidence(): Observable<any> {
    return this.transferState.runApi(
      'catalog-product-in-evidence',
      this.apiService.translateApi(
        (locale) => this.apiService.get(Config.ENDPOINTS.catalog.productInEvidence(), undefined, undefined, locale)
      )
    );
  }

  public getApplications(): Observable<any> {
    return this.transferState.runApi(
      'catalog-applications',
      this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.applications(), undefined, undefined, locale))
    );
  }

  public getSeries(): Observable<any> {
    return this.transferState.runApi(
      'catalog-series',
      this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.series(), undefined, undefined, locale))
    );
  }

  public getSerieInEvidence(): Observable<any> {
    return this.transferState.runApi(
      'catalog-serie-in-evidence',
      this.apiService.translateApi(
        locale => this.apiService.get(Config.ENDPOINTS.catalog.serieInEvidence(), undefined, undefined, locale)
      )
    );
  }

  public getProducts(): Observable<any> {
    return this.transferState.runApi(
      'catalog-products',
      this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.allProducts(), undefined, undefined, locale))
    );
  }

  public getProduct(slug: string): Observable<any> {
    return this.transferState.runApi(
      `catalog-product-${slug}`,
      this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.product(slug), undefined, undefined, locale))
    );
  }

  public getAccessoriesCategories(machine = ''): Observable<any> {
    return this.transferState.runApi(
      `catalog-accessories-categories-${machine}`,
      this.apiService.translateApi(
        locale => this.apiService.get(Config.ENDPOINTS.catalog.accessoriesCategories(machine), undefined, undefined, locale)
      )
    );
  }

  public getKitseries(): Observable<any> {
    return this.transferState.runApi(
      'catalog-kitseries',
      this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.kitseries(), undefined, undefined, locale))
    );
  }

  public getKitserie(slug): Observable<any> {
    return this.transferState.runApi(
      `catalog-kitserie-${slug}`,
      this.apiService.translateApi(locale => this.apiService.get(Config.ENDPOINTS.catalog.kitserie(slug), undefined, undefined, locale))
    );
  }

  public getProductsWithLocale(locale): Observable<any> {
    return this.transferState.runApi(
      'catalog-products',
      this.apiService.get(Config.ENDPOINTS.catalog.allProducts(), undefined, undefined, locale)
    );
  }

  public getKitseriesWithLocale(locale): Observable<any> {
    return this.transferState.runApi(
      'catalog-kitseries',
      this.apiService.get(Config.ENDPOINTS.catalog.kitseries(), undefined, undefined, locale)
    );
  }

  public getProductInEvidenceWithLocale(locale): Observable<any> {
    return this.transferState.runApi(
      'catalog-product-in-evidence',
      this.apiService.get(Config.ENDPOINTS.catalog.productInEvidence(), undefined, undefined, locale)
    );
  }

  public compareProducts(firstProduct: string, secondaryProduct: string): Observable<any> {
    return this.apiService.translateApi(locale => this.apiService.get(
      Config.ENDPOINTS.catalog.compareProducts(firstProduct, secondaryProduct), undefined, undefined, locale)
    );
  }
}
