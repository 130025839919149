import {FormGroup} from '@angular/forms';

export class CrmFormHelper {
  public combinePhoneWithPrefix(targetForm: FormGroup, prefixFormFieldId: string, phoneFormFieldId: string) {
    const phonePrefix = targetForm.get(prefixFormFieldId).value;
    if (phonePrefix !== '') {
      const phone = targetForm.get(phoneFormFieldId).value;
      const phoneWithPrefix = `${phonePrefix} ${phone}`;
      targetForm.get(phoneFormFieldId).setValue(phoneWithPrefix);
    }
  }

  public combineCityWithNation(targetForm: FormGroup, cityFormFieldId: string, nationFormFieldId: string) {
    const city = targetForm.get(cityFormFieldId).value;
    if (city !== '') {
      const nation = targetForm.get(nationFormFieldId).value;
      const cityWithNation = `${city} (${nation})`;
      targetForm.get(cityFormFieldId).setValue(cityWithNation);
    }
  }
}
