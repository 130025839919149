import {Pipe, PipeTransform} from '@angular/core';

import * as moment from 'moment';
import {UtilsService} from '../services/utils.service';

@Pipe({
  name: 'myDate'
})
export class MyDatePipe implements PipeTransform {

  constructor(private utilsService: UtilsService) {
  }

  transform(value: any, args?: any): any {
    if (this.utilsService.isPlatformBrowser) {
      if (args) {
        if (value !== '') {
          return moment(value).locale(navigator.language).format(args);
        } else {
          return moment().locale(navigator.language).format(args);
        }
      } else {
        if (value !== '') {
          return moment(value).locale(navigator.language).format('LL');
        } else {
          return moment().locale(navigator.language).format('LL');
        }
      }
    } else {
      return value;
    }
  }

}
