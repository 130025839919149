<footer class="container-fluid">
  <div class="row">
    <div class="col-60 bg-gray font-weight-regular">
      <div class="container">
        <div class="row">
          <div class="col-60 pt-3 footer-border-height">
            <div class="d-flex flex-column align-items-center w-100 py-3">

              <!-- Footer content section -->
              <div class="d-flex flex-column flex-lg-row justify-content-lg-between footer-container">

                <!-- Footer company info column -->
                <div class="d-flex flex-column align-items-lg-start footer-elsa-info">
                  <img
                    style="filter: brightness(0);"
                    src="https://media.elsasrl.com/storage/elsa-platform/media/be-xtra/logo-elsa-site.png"
                    class="footer-logo" alt="Elsa">
                  <p
                    class="text-uppercase  text-center footer-title mb-0 text-lg-left elsa-title-font">{{'footer_title' | translate}}</p>
                  <p class="footer-description">{{'footer_description' | translate}}</p>
                  <p class="text-center footer-place text-lg-left">{{contacts?.default.position.address_line_1}}
                    <br>{{contacts?.default.position.address_line_2}}</p>
                </div>

                <!-- Footer company links column -->
                <div class="d-flex flex-column footer-elsa-link-column">
                  <p
                    class="text-center text-lg-left text-uppercase label-contacts elsa-title-font">{{'footer_label_company' | translate}}</p>
                  <div class="container p-lg-0">
                    <div class="d-flex flex-row justify-content-center">
                      <ul class="list-unstyled footer-items-contact">
                        <li>
                          <a [routerLink]="Paths.AboutUs | page | localize"
                             [title]="PathNames[Paths.AboutUs] | translate"
                             appEmptyTitle
                             class="footer-item-link">{{'footer_label_about_us' | translate}}</a>
                        </li>
                        <li *ngIf="catalog$ | async as catalog">
                          <a [href]="catalog"
                             [title]="'footer_label_catalog' | translate"
                             target="_blank"
                             appEmptyTitle
                             class="footer-item-link">{{'footer_label_catalog' | translate}}</a>
                        </li>
                        <li *ngIf="caseStudy$ | async as caseStudy">
                          <a [href]="caseStudy"
                             [title]="'footer_label_case_studies' | translate"
                             target="_blank"
                             appEmptyTitle
                             class="footer-item-link">{{'footer_label_case_studies' | translate}}</a>
                        </li>
                        <li>
                          <a [routerLink]="Paths.News | page  | localize" [title]="PathNames[Paths.News] | translate"
                             appEmptyTitle
                             class="footer-item-link">{{'footer_label_blog' | translate}}</a>
                        </li>
                        <li>
                          <a [routerLink]="Paths.Elsa | page  | localize" [title]="PathNames[Paths.Elsa] | translate"
                             appEmptyTitle
                             class="footer-item-link">{{'footer_label_contact_us' | translate}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Footer legal links column -->
                <div class="d-flex flex-column footer-elsa-link-column">
                  <p
                    class="text-center text-lg-left text-uppercase label-contacts elsa-title-font">{{'footer_label_legal' | translate}}</p>
                  <div class="container p-lg-0">
                    <div class="d-flex flex-row justify-content-center">
                      <ul class="list-unstyled footer-items-contact">
                        <li>
                          <a title="Elsa srl | Cookie Policy" appEmptyTitle
                             [routerLink]="Paths.Cookies | page | localize"
                             class="footer-item-link">{{PathNames[Paths.Cookies] | translate}}</a>
                        </li>
                        <li>
                          <a title="Elsa srl | Privacy Policy" appEmptyTitle
                             [routerLink]="Paths.Privacy | page | localize"
                             class="footer-item-link">{{PathNames[Paths.Privacy] | translate}}</a>
                        </li>
                        <li>
                          <a title="Elsa srl | Whistleblowing" appEmptyTitle
                             [href]="'footer_url_whistleblowing' | translate | safeUrl"
                             class="footer-item-link">{{'footer_label_whistleblowing' | translate}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- Footer company & legal links column for mobile-->
                <div class="footer-elsa-link-menu">
                  <div class="w-100 d-flex flex-row justify-content-center">
                    <a class="elsa-title-font label-dropdown-menu" appEmptyTitle
                       (click)="companyIsOpen =!companyIsOpen">{{'footer_label_company' | translate | uppercase}}</a>
                    <a (click)="companyIsOpen =!companyIsOpen" class="button-dropdown" title="Elsa Srl"
                       appEmptyTitle>
                      <img class="elsa-transition" src="assets/icons/icon_down_arrow_small_black.svg" height="10"
                           alt="Elsa Srl"
                           [class.dropdown-menu-open]="companyIsOpen">
                    </a>
                  </div>
                  <div *ngIf=companyIsOpen
                       class="d-flex flex-column justify-content-center footer-dropdown-item-container">
                    <a [routerLink]="Paths.AboutUs | page | localize"
                       [title]="PathNames[Paths.AboutUs] | translate"
                       appEmptyTitle
                       class="footer-dropdown-item-link">{{'footer_label_about_us' | translate}}</a>
                    <a *ngIf="catalog$ | async as catalog"
                       [href]="catalog"
                       [title]="'footer_label_catalog' | translate"
                       target="_blank"
                       appEmptyTitle
                       class="footer-dropdown-item-link">{{'footer_label_catalog' | translate}}</a>
                    <a *ngIf="caseStudy$ | async as caseStudy"
                       [href]="caseStudy"
                       [title]="'footer_label_case_studies' | translate"
                       target="_blank"
                       appEmptyTitle
                       class="footer-dropdown-item-link">{{'footer_label_case_studies' | translate}}</a>
                    <a [routerLink]="Paths.News | page  | localize" [title]="PathNames[Paths.News] | translate"
                       appEmptyTitle
                       class="footer-dropdown-item-link">{{'footer_label_blog' | translate}}</a>
                    <a [routerLink]="Paths.Elsa | page  | localize" [title]="PathNames[Paths.Elsa] | translate"
                       appEmptyTitle
                       class="footer-dropdown-item-link">{{'footer_label_contact_us' | translate}}</a>
                  </div>

                  <div class="w-100 d-flex flex-row justify-content-center">
                    <a class="elsa-title-font label-dropdown-menu"
                       appEmptyTitle>{{'footer_label_legal' | translate | uppercase}}</a>
                    <a (click)="legalIsOpen =!legalIsOpen" class="button-dropdown" title="Elsa Srl"
                       appEmptyTitle>
                      <img class="elsa-transition" src="assets/icons/icon_down_arrow_small_black.svg" height="10"
                           alt="Elsa Srl"
                           [class.dropdown-menu-open]="legalIsOpen">
                    </a>
                  </div>
                  <div *ngIf=legalIsOpen
                       class="d-flex flex-column justify-content-center footer-dropdown-item-container">
                    <a title="Elsa srl | Cookie Policy" appEmptyTitle
                       [routerLink]="Paths.Cookies | page | localize"
                       class="footer-dropdown-item-link">{{PathNames[Paths.Cookies] | translate}}</a>
                    <a title="Elsa srl | Privacy Policy" appEmptyTitle
                       [routerLink]="Paths.Privacy | page | localize"
                       class="footer-dropdown-item-link">{{PathNames[Paths.Privacy] | translate}}</a>
                    <a title="Elsa srl | Whistleblowing" appEmptyTitle
                       [href]="'footer_url_whistleblowing' | translate | safeUrl"
                       class="footer-dropdown-item-link">{{'footer_label_whistleblowing' | translate}}</a>
                  </div>
                </div>

                <!-- Footer Newsletter column -->
                <div class="d-flex flex-column footer-elsa-newsletter">
                  <p
                    class="text-center text-lg-left  text-uppercase label-newsletter elsa-title-font">{{'label_newsletter' | translate}}</p>
                  <p class="text-center text-lg-left text-newsletter">{{'footer_newsletter' | translate}}</p>
                  <div class="container p-lg-0">
                    <div class="row">
                      <div class="col-60">
                        <form (ngSubmit)="onSubmitNewsletter()" [formGroup]="newsletterForm"
                              class="d-flex flex-column flex-lg-column align-items-center align-items-lg-start p-md-2 p-lg-0 box-newsletter w-100">
                          <input class="form-control newsletter-control" formControlName="email">
                          <app-form-control-validator class="w-100"
                                                      [elsaControl]="newsletterForm.get('email')"></app-form-control-validator>
                          <div
                            class="custom-control custom-checkbox align-self-baseline align-self-md-center mt-1 container-checkbox">
                            <input class="custom-control-input newsletter-form-privacy-checkbox" type="checkbox"
                                   formControlName="privacy" id="privacy">

                            <label class="custom-control-label newsletter-form-privacy"
                                   for="privacy">{{'label_privacy'|translate}} <a target="_blank"
                                                                                  class="text-link-decorated"
                                                                                  [routerLink]="Paths.Privacy|page|localize">({{'path_name_privacy'|translate}}
                              )</a></label>
                            <app-form-control-validator
                              [elsaControl]="newsletterForm.get('privacy')"></app-form-control-validator>
                          </div>
                          <button class="btn btn-sm btn-gray text-white newsletter-button text-uppercase" type="submit">
                            {{'label_subscription' | translate}}
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Footer social section -->
              <div
                class="d-flex flex-row justify-content-center w-100 box-social">
                <a *ngFor="let item of contacts?.socials" [href]="item.url" target="_blank"
                   class="mb-4 mr-2 ml-2 mr-lg-3 ml-lg-3"
                   title="{{item.title}}" appEmptyTitle>
                  <img [src]="item.img.path" class="icon-social" alt="{{item.title}}"/>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer copyright section -->
    <div class="col-60 bg-dark">
      <div class="d-flex flex-row justify-content-center align-items-center footer-disclaimer">
        <span class="text-center font-weight-light text-white"
              [innerHTML]="'label_copyright' | translate | safeHtml"></span>
      </div>
    </div>
  </div>
</footer>
<app-script-ld-json [json]="schemaContact"></app-script-ld-json>
