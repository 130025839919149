import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header-link-in-evidence',
  templateUrl: './header-link-in-evidence.component.html',
  styleUrls: ['./header-link-in-evidence.component.scss']
})
export class HeaderLinkInEvidenceComponent implements OnInit {

  isEnabled: boolean;

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
    this.isEnabled = this.translateService.instant('header_link_in_evidence_enabled') === 'true';
    this.isEnabled = true;
  }

  navigateToLink() {
    window.open(this.translateService.instant('header_link_in_evidence_url'), '_blank');
  }
}
