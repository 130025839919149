import { Config } from '../../../config/config';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./transfer-state.service";
export class PagesService {
    constructor(apiService, transferState) {
        this.apiService = apiService;
        this.transferState = transferState;
    }
    getSeoPage(slug) {
        return this.transferState.runApi(`pages-page-${slug}-seo`, this.apiService.translateApi(lang => this.apiService.get(Config.ENDPOINTS.pages.seo(slug), undefined, undefined, lang)));
    }
    getPage(slug) {
        return this.transferState.runApi(`pages-page-${slug}`, this.apiService.translateApi(lang => this.apiService.get(Config.ENDPOINTS.pages.page(slug), undefined, undefined, lang)));
    }
    getHistories() {
        return this.transferState.runApi('pages-histories', this.apiService.translateApi(lang => this.apiService.get(Config.ENDPOINTS.pages.histories(), undefined, undefined, lang)));
    }
    getApplicationsWithLocale(locale) {
        return this.transferState.runApi('pages-applications', this.apiService.get(Config.ENDPOINTS.pages.applications(), undefined, undefined, locale));
    }
    getHistoriesWithLocale(locale) {
        return this.transferState.runApi('pages-histories', this.apiService.get(Config.ENDPOINTS.pages.histories(), undefined, undefined, locale));
    }
    getPageWithLocale(slug, locale) {
        return this.transferState.runApi(`pages-page-${slug}`, this.apiService.get(Config.ENDPOINTS.pages.page(slug), undefined, undefined, locale));
    }
}
PagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PagesService_Factory() { return new PagesService(i0.ɵɵinject(i1.ApiService), i0.ɵɵinject(i2.TransferStateService)); }, token: PagesService, providedIn: "root" });
