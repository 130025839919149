import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toasterService: ToastrService) {
  }

  public success(message) {
    this.toasterService.success(message);
  }

  public error(message) {
    this.toasterService.error(message);
  }
}
