/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./script-ld-json.component";
import * as i2 from "@angular/platform-browser";
var styles_ScriptLdJsonComponent = [];
var RenderType_ScriptLdJsonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScriptLdJsonComponent, data: {} });
export { RenderType_ScriptLdJsonComponent as RenderType_ScriptLdJsonComponent };
export function View_ScriptLdJsonComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_ScriptLdJsonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-script-ld-json", [], [[8, "innerHTML", 1]], null, null, View_ScriptLdJsonComponent_0, RenderType_ScriptLdJsonComponent)), i0.ɵdid(1, 49152, null, 0, i1.ScriptLdJsonComponent, [i2.DomSanitizer], null, null)], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).jsonLD; _ck(_v, 0, 0, currVal_0); }); }
var ScriptLdJsonComponentNgFactory = i0.ɵccf("app-script-ld-json", i1.ScriptLdJsonComponent, View_ScriptLdJsonComponent_Host_0, { json: "json" }, {}, []);
export { ScriptLdJsonComponentNgFactory as ScriptLdJsonComponentNgFactory };
