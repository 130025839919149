import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
export class ToasterService {
    constructor(toasterService) {
        this.toasterService = toasterService;
    }
    success(message) {
        this.toasterService.success(message);
    }
    error(message) {
        this.toasterService.error(message);
    }
}
ToasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToasterService_Factory() { return new ToasterService(i0.ɵɵinject(i1.ToastrService)); }, token: ToasterService, providedIn: "root" });
