<div class="row mx-lg-0">
  <div class="col-60 breadcrumb-elsa" #backgroundRef>
    <ul class="mb-0 h-100 breadcrumb-elsa-padding list-unstyled">
      <li class="list-inline-item breadcrumb-elsa-item mt-0 mr-2">
        <a [routerLink]="Paths.Homepage | page | localize" title="Homepage | Elsa Srl" appEmptyTitle>
          <img src="assets/icons/icon_home.svg" alt="Homepage | Elsa Srl"
               height="10">
        </a>
      </li>
      <li class=" breadcrumb-elsa-item">
        <a [routerLink]="Paths.Homepage | page | localize" class=" elsa-body-font breadcrumb-elsa-link" [title]="PathNames[Paths.Homepage] | translate" appEmptyTitle>
          <span class="breadcrumb-elsa-item-path">{{PathNames[Paths.Homepage] | translate}}</span>
        </a>
      </li>
      <li class=" breadcrumb-elsa-item mt-0">
        <span class="elsa-body-font breadcrumb-elsa-link px-1">/</span>
      </li>
      <ng-container *ngFor="let item of breadcrumbService.observableUrls | async">
        <li class=" breadcrumb-elsa-item">
          <a [routerLink]="item.link | page: item.args | localize" [title]="item.label | translate" appEmptyTitle
            class=" elsa-body-font breadcrumb-elsa-link">
            <span class="breadcrumb-elsa-item-path">{{item.label | translate}}
            </span>
          </a>
        </li>
        <li class=" breadcrumb-elsa-item mt-0">
          <span class="elsa-body-font breadcrumb-elsa-link px-1">/</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
