import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private title: Title, private translateService: TranslateService, @Inject(DOCUMENT) private dom) {
  }

  public setTags(title, description = '', image = '', links: { [key: string]: string } | null = null) {
    const currentLanguage = this.translateService.currentLang;
    this.dom.documentElement.lang = currentLanguage;

    const currentUrl = this.getCurrentUrl(currentLanguage, links);

    title = `${title} | Elsa Srl`;
    this.title.setTitle(title);

    const metaTags = [
      {
        name: 'description',
        content: description
      },
      {
        name: 'twitter:card',
        content: 'summary'
      },
      {
        name: 'twitter:description',
        content: description
      },
      {
        name: 'twitter:image',
        content: image
      },
      {
        name: 'twitter:site',
        content: '@Elsa_srl'
      },
      {
        name: 'twitter:title',
        content: title
      },
      {
        property: 'og:description',
        content: description
      },
      {
        property: 'og:image',
        content: image
      },
      {
        property: 'og:locale',
        content: currentLanguage
      },
      {
        property: 'og:site_name',
        content: 'Elsa srl'
      },
      {
        property: 'og:title',
        content: title
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:url',
        content: currentUrl
      }
    ];

    this.updateGenericMeta(metaTags);

    this.setItempropMetaTags(description, image, title);

    if (links) {
      Object.keys(links).forEach(lang => {
        this.setLinkTag(lang, links[lang]);
      });
      this.setLinkTag('x-default', links.it);
    }
    this.setCanonicalUrl(currentUrl);
  }

  private getCurrentUrl(currentLanguage: string, links: { [key: string]: string } | null) {
    if (links && Object.keys(links).includes(currentLanguage)) {
      return links[currentLanguage];
    }

    const currentUrl = new URL(this.dom.URL);
    currentUrl.searchParams.sort();
    return currentUrl.toString();
  }

  private setItempropMetaTags(description: string, image: string, title) {
    this.setItempropTag('description', description);
    this.setItempropTag('image', image);
    this.setItempropTag('name', title);
  }

  private setItempropTag(tagName: string, tagValue: string) {
    const tag = this.meta.getTag(`itemprop=${tagName}`);
    if (tag) {
      tag.content = tagValue;
    } else {
      this.meta.addTag({
        itemprop: tagName,
        content: tagValue
      });
    }
  }

  private setLinkTag(lang: string, url: string) {
    let link = this.dom.querySelector(`link[rel=alternate][hreflang=${lang}]`);
    if (!link) {
      link = HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'alternate');
      this.dom.head.appendChild(link);
    }
    link.setAttribute('hreflang', lang);
    link.setAttribute('href', url);
  }

  private setCanonicalUrl(canonicalUrl: string) {
    let linkElement = this.dom.querySelector('link[rel=canonical]');
    if (!linkElement) {
      linkElement = this.dom.createElement('link');
      linkElement.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(linkElement);
    }
    linkElement.setAttribute('href', canonicalUrl);
  }

  private updateGenericMeta(metaTags: MetaDefinition[]) {
    metaTags.forEach(metaTag => {
      if (metaTag.content !== '') {
        this.meta.updateTag(metaTag);
      } else {
        this.meta.removeTag(`property='${metaTag.property}'`);
      }
    });
  }
}
