import {Injectable} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {ApiService} from './api.service';
import {Config} from '../../../config/config';
import {catchError, concatMap, filter, first, take} from 'rxjs/operators';
import {FormGroup} from '@angular/forms';
import {NavigationEnd, Router} from '@angular/router';
import {ToasterService} from './toaster.service';
import {AnalyticsService} from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private apiService: ApiService, private router: Router,
              private alertService: ToasterService, private analyticsService: AnalyticsService) {
  }

  public newsletterSubscribe(formGroup: FormGroup): Observable<any> {
    return this.apiService.post(Config.ENDPOINTS.request.newsletter(), formGroup.value).pipe(catchError(err => {
      if (err.status === Config.ELSA_VALIDATION_FORMS_STATUS_CODE) {
        this.setErrorsOnForm(formGroup, err.error);
      } else {
        this.alertService.error(err.headers.get('message'));
      }
      throw err;
    }));
  }

  public sendRequest(type: Forms, formGroup: FormGroup): Observable<any> {

    return of(true).pipe(
      concatMap(() => {
        return this.apiService.post(Config.ENDPOINTS.request.sendRequest(type), formGroup.value);
      }),
      first(value => {
        this.analyticsService.formSubmission(type);
        return value;
      }),
      catchError(err => {
        if (err.status === Config.ELSA_VALIDATION_FORMS_STATUS_CODE) {
          this.setErrorsOnForm(formGroup, err.error);
        }
        throw err;
      })
    );
  }

  private setErrorsOnForm(formGroup, errors: { [key: string]: string[] }) {
    const subscriptions = new Subscription();
    Object.keys(errors).forEach(controlName => {
      const control = formGroup.get(controlName);
      control.setErrors({[Config.ELSA_VALIDATION_FORMS_KEY]: errors[controlName]});
      subscriptions.add(
        control.valueChanges.pipe(take(1)).subscribe(() => control.setErrors(null))
      );
    });
    this.router.events.pipe(filter(e => e instanceof NavigationEnd), take(1)).subscribe(() => subscriptions.unsubscribe());
  }
}

export enum Forms {
  CONTACTS = 'contacts',
  QUOTATION = 'quotations',
  RESELLER = 'resellers'
}
