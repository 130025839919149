import {
  BrowserModule,
  BrowserTransferStateModule,
  HAMMER_GESTURE_CONFIG,
  TransferState
} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {CustomTranslateLoaderFactory} from './helpers/custom-translate-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {LayoutModule} from './layout/layout.module';
import {SWIPER_CONFIG, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {CustomHammerConfig} from './hammerjs-fixer/CustomHammerConfig';
import {PipesModule} from './providers/pipes/pipes.module';
import {FroalaViewModule} from 'angular-froala-wysiwyg';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  preloadImages: false,
  speed: 800,
  autoplay: false,
  loop: true,
  mousewheel: true,
  navigation: false,
  pagination: {
    type: 'bullets',
    el: '.swiper-pagination',
    clickable: true
  }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: CustomTranslateLoaderFactory, deps: [HttpClient, TransferState]}
    }),
    LayoutModule,
    ToastrModule.forRoot(
      {
        positionClass: 'toast-center-center',
      }
    ),
    BrowserTransferStateModule,
    PipesModule,
    FroalaViewModule.forRoot()
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    {provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig}

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
