import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {LocalizeRouterHttpLoaderFactory} from './helpers/custom-translate-loader';
import {StoreTranslateService} from './providers/services/store-translate.service';
import {SemiPath} from '../config/config';
import {HttpClient} from '@angular/common/http';
import {TransferState} from '@angular/platform-browser';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/root/root.module').then(r => r.RootModule)
  },
  {
    path: SemiPath.Products,
    loadChildren: () => import('./pages/products/products.module').then(r => r.ProductsModule)
  },
  {
    path: SemiPath.AboutUs,
    loadChildren: () => import('./pages/about-us/about-us.module').then(r => r.AboutUsModule)
  },
  {
    path: SemiPath.Elsa,
    loadChildren: () => import('./pages/elsa/elsa.module').then(r => r.ElsaModule)
  },
  // {
  //   path: SemiPath.Contacts,
  //   loadChildren: () => import('./pages/contacts/contacts.module').then(r => r.ContactsModule)
  // },
  {
    path: SemiPath.News,
    loadChildren: () => import('./pages/news/news.module').then(r => r.NewsModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: `/${SemiPath.NotFound}`
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: LocalizeRouterHttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, StoreTranslateService, HttpClient, TransferState]
      }
    })
  ],
  exports: [RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule {
}
