<div class="container">
  <div class="row">
    <div class="col-60">
      <div class="position-relative">
        <div class="position-absolute button-go-up-container">
          <div class="d-flex flex-column align-items-center  ">
            <a (click)="goUp()" title="Back to top!" class="d-flex flex-column align-items-center translate" appEmptyTitle>
              <img alt="Back to top!" src="assets/icons/icon_navigation_up.svg" class="go-up-img">
              <span class="back-to-top">{{'label_go_up'| translate}}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--py-3-->
